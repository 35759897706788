export function isTransactionCredit(transaction) {
    return /^(credit|terminal)$/i.test(transaction.raw_transaction_type)
}

export function isTransactionTerminal(transaction) {
    return /^(terminal)$/i.test(transaction.raw_transaction_type)
}

export function isTransactionProcessing(transaction) {
    return /^(processing)$/i.test(transaction.adyen_status)
}

export function isTransactionSettled(transaction=null) {
    return !!transaction ? transaction.settled === true : false
}

export function isTransactionRefunded(transaction=null) {
    return !!transaction ? transaction.refunded === true : false
}

export function isTransactionCancellationRequested(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !!transaction.cancellation_requested_at
        : false
}

export function isTransactionCancelling(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && isTransactionCancellationRequested(transaction) && !transaction.cancelled_at
        : false
}

export function isTransactionCancelled(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !!transaction.cancelled_at
        : false
}

export function isTransactionPendingPreAuth(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !isTransactionCancelled(transaction) && transaction.captured_at === null
        : false
}

export function isTransactionAdjustmentPending(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !!transaction.pending_amount_adjustment && Object.values(transaction.pending_amount_adjustment).length > 0
        : false
}

export function isTransactionReadyToCapture(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !!transaction.ready_to_capture_at
        : false
}

export function isTransactionCaptureRequested(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !!transaction.capture_requested_at
        : false
}

export function isTransactionCapturing(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && isTransactionCaptureRequested(transaction) && !transaction.captured_at
        : false
}

export function isTransactionCaptured(transaction=null) {
    return !!transaction
        ? isTransactionCredit(transaction) && !!transaction.captured_at
        : false
}
