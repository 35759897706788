import React, { useEffect, useState, useMemo, lazy, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorBoundary } from 'react-error-boundary'
import { useForm, FormProvider } from 'react-hook-form'
import { setLocation } from '@/features/Locations/locationSlice'

import {
    fetchCheck,
    fetchCompReasons,
    fetchFulfillmentProviders,
    fetchPrinters,
    selectComponent,
    selectDeviceSettings,
    selectInDeviceSetupMode,
    selectIsInitialized,
    selectIsSidebarOpen,
    setInitialized,
    setCurrentComponent,
    setDeviceSettings,
    setDeviceSetupMode,
    setInMoveItemsMode,
    configureModal,
    resetCheckout,
    enableIndividualTabPayments,
    selectCurrentCheck,
    unlockTabPayments,
    switchToLocation,
    toggleMenuSearch,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import {
    fetchUser,
    selectHasError,
    selectIsSessionLocked,
    selectCurrentUser,
    setIsUserRestrictedToPos,
    setCurrentUser,
    setLogoutPath,
    setReturnUrl,
    setSessionLocked,
    updateIsSessionLocked,
} from '@/features/Session/sessionSlice'

import Navigation from '@/features/AdvancedPointOfSale/components/Navigation'
import GeneralSidebar from '@/features/AdvancedPointOfSale/components/sidebars/General'
import MenusContainer from '@/features/AdvancedPointOfSale/MenusContainer'
import OpenChecks from '@/features/AdvancedPointOfSale/OpenChecks'
import ChecksInAlteration from '@/features/AdvancedPointOfSale/ChecksInAlteration'
import PrintPay from '@/features/AdvancedPointOfSale/PrintPay'
import Checkout from '@/features/AdvancedPointOfSale/Checkout'
import ReservationSplitting from '@/features/AdvancedPointOfSale/ReservationSplitting'
import AddCardToTabModal from '@/features/AdvancedPointOfSale/components/modals/AddCardToTabModal'
import AddToChitTabModal from '@/features/AdvancedPointOfSale/components/modals/AddToChitTabModal'
import ChangeStaffModal from '@/features/AdvancedPointOfSale/components/modals/ChangeStaffModal'
import CompDetailsModal from '@/features/AdvancedPointOfSale/components/modals/CompDetailsModal'
import CreateCheckModal from '@/features/AdvancedPointOfSale/components/modals/CreateCheckModal'
import CreateNewTabModal from '@/features/AdvancedPointOfSale/components/modals/CreateNewTabModal'
import EditCardOnTabModal from '@/features/AdvancedPointOfSale/components/modals/EditCardOnTabModal'
import EmailReceiptModal from '@/features/AdvancedPointOfSale/components/modals/EmailReceiptModal'
import ReportModal from '@/features/AdvancedPointOfSale/components/modals/ReportModal'
import ManageCheckModal from '@/features/AdvancedPointOfSale/components/modals/ManageCheckModal'
import ManageCheckItemModal from '@/features/AdvancedPointOfSale/components/modals/ManageCheckItemModal'
import ManageChitItemModal from '@/features/AdvancedPointOfSale/components/modals/ManageChitItemModal'
import PrintOptionModal from '@/features/AdvancedPointOfSale/components/modals/PrintOptionModal'
import ReceiptModal from '@/features/AdvancedPointOfSale/components/modals/ReceiptModal'
import RenameTabModal from '@/features/AdvancedPointOfSale/components/modals/RenameTabModal'
import AddMembershipModal from '@/features/AdvancedPointOfSale/components/modals/AddMembershipModal'
import SelectTabModal from '@/features/AdvancedPointOfSale/components/modals/SelectTabModal'
import AlterationModal from '@/features/AdvancedPointOfSale/components/modals/AlterationModal'
import SearchModal from '@/features/AdvancedPointOfSale/components/modals/SearchModal'
import TransactionsModal from '@/features/AdvancedPointOfSale/components/modals/TransactionsModal'
import { LMFAO } from '@/features/AdvancedPointOfSale/components/Messages'
import { ADV_POS_SESSION } from '@/lib/Storage'
import addVirtualKeyboardSupport from '@/lib/addVirtualKeyboardSupport'
import scrollHinting from '@/lib/ScrollHinting'
import { debug } from '@/lib/Debug'

let enteringDeviceSetupMode = false

export default function KurtLoader(props) {

    addVirtualKeyboardSupport()

    const formMethods           = useForm({ mode: 'all' })
    const dispatch              = useDispatch()
    const { showBoundary }      = useErrorBoundary()
    const isInitialized         = useSelector(selectIsInitialized)
    const hasError              = useSelector(selectHasError)
    const component             = useSelector(selectComponent)
    const isSessionLocked       = useSelector(selectIsSessionLocked)
    const isSidebarOpen         = useSelector(selectIsSidebarOpen)
    const inDeviceSetupMode     = useSelector(selectInDeviceSetupMode)
    const currentUser           = useSelector(selectCurrentUser)
    const check                 = useSelector(selectCurrentCheck)
    const deviceSettings        = useSelector(selectDeviceSettings)
    let localStorage            = JSON.parse(window.localStorage.getItem(ADV_POS_SESSION))
    let sessionStorage          = JSON.parse(window.sessionStorage.getItem(ADV_POS_SESSION))

    const [dynamicContent, setDynamicContent] = useState(null)

    const CompsContainer = lazy(() => import('@/features/AdvancedPointOfSale/CompsContainer'))
    const ManageCheck    = lazy(() => import('@/features/AdvancedPointOfSale/ManageCheck'))
    const EightySix      = lazy(() => import('@/features/AdvancedPointOfSale/EightySix'))
    const Settings       = lazy(() => import('@/features/AdvancedPointOfSale/Settings'))
    const CloseOfDay     = lazy(() => import('@/features/AdvancedPointOfSale/CloseOfDay'))
    const EndOfShift     = lazy(() => import('@/features/AdvancedPointOfSale/EndOfShift'))
    const DeviceSetup    = lazy(() => import('@/features/AdvancedPointOfSale/components/DeviceSetup'))

    // application key-combo shortcuts
    const handleApplicationShortcuts = (e) => {
        e.stopPropagation()

        switch (true) {
            // CTRL-L or CTRL-S to Fast User Switch / Lock
            case e.ctrlKey && /^[l|s]{1}$/i.test(e.key) : {
                dispatch(updateIsSessionLocked(true, false))
                    .then(() => { dispatch(setSessionLocked(true)) })
                    .catch((e) => { showBoundary(e) })

                break
            }

            // CTRL-? or /
            case /^[\/]{1}$/i.test(e.key) :
            case e.ctrlKey && /^[?]{1}$/i.test(e.key) : {
                dispatch(toggleMenuSearch('open'))
                break
            }
        }
    }

    const fetchDependencies = () => {
        dispatch(fetchCompReasons({ unlessPopulated: true }))
        dispatch(fetchFulfillmentProviders({ unlessPopulated: true }))
        dispatch(fetchPrinters({ unlessPopulated: true }))
    }

    const renderComponent = useMemo(() => {
        switch (component) {
            case 'DefaultMenu' : return (
                <MenusContainer content='Menus' {...props} shouldLoadDefault onChange={setDynamicContent} />
            )

            case 'ManageCheck' : return (
                <ManageCheck {...props} onChange={setDynamicContent} />
            )

            case 'Menus' : return (
                <MenusContainer content='Menus' {...props} onChange={setDynamicContent} />
            )

            case 'MenuItem' : return (
                <MenusContainer content='MenuItem' {...props} onChange={setDynamicContent} />
            )

            case 'OpenChecks' : return (
                <OpenChecks {...props} onChange={setDynamicContent} />
            )

            case 'ChecksInAlteration' : return (
                <ChecksInAlteration {...props} onChange={setDynamicContent} />
            )

            case 'Comps' : return (
                <CompsContainer {...props} onChange={setDynamicContent} />
            )

            case 'EightySix' : return (
                <EightySix {...props} onChange={setDynamicContent} />
            )

            case 'EndOfShift' : return (
                <EndOfShift {...props} onChange={setDynamicContent} />
            )

            case 'CloseOfDay' : return (
                <CloseOfDay {...props} onChange={setDynamicContent} />
            )

            case 'Settings' : return (
                <Settings {...props} onChange={setDynamicContent} />
            )

            case 'DeviceSetup' : return (
                <DeviceSetup {...props} onChange={setDynamicContent} />
            )

            case 'EndOfShift' : return (
                <EndOfShift {...props} onChange={setDynamicContent} />
            )

            case 'PrintPay' : return (
                <PrintPay {...props} onChange={setDynamicContent} />
            )

            case 'Checkout' : return (
                <Checkout {...props} onChange={setDynamicContent} />
            )

            case 'ReservationSplitting' : return (
                <ReservationSplitting {...props} onChange={setDynamicContent} />
            )

            default :
                throw('Attempted To Load An Unknown Component')
        }
    }, [component])

    /*
     * Load initial state
     */
    useEffect(() => {
        if (debug && console) {
            console.log(
                `%c${window.atob("SGksIEknbSBLdXJ0IExvZGVyIHdpdGggYW4gTVRWIE5ld3MgQnJpZWYuLi4=")}`,
                'color: green; background: yellow; font-size: 14px'
            )
        }

        // immediately check if the device has been setup, if not force setup mode
        if ((!!deviceSettings || deviceSettings?.location?.id || deviceSettings?.name) === false) {
            enteringDeviceSetupMode = true
            dispatch(setDeviceSetupMode(true))
        }

        // handle immediate redirects if necessary
        if (!!deviceSettings?.location?.id && props.location.id != deviceSettings.location.id)
        {
            // for a user with access to multiple locations coming in from the admin side,
            // we want to allow the url to override the stored device settings because they
            // may have context switched before coming into the POS (but we keep the same device name)
            if (props.canInitialLoadManyLocations && !props.currentUser.is_restricted_to_pos)
            {
                // update the location in the localStorage device
                // settings and immediately redirect to that location
                if (!!localStorage) {
                    localStorage.device_settings.location.id   = props.location.id
                    localStorage.device_settings.location.name = props.location.name

                    window.localStorage.setItem(ADV_POS_SESSION, JSON.stringify(localStorage))
                    dispatch(setDeviceSettings(localStorage.device_settings))

                    dispatch(switchToLocation(props.location.id))

                    dispatch(updateIsSessionLocked(true, false))
                        .then(() => { dispatch(setSessionLocked(true)) })
                        .catch((e) => { showBoundary(e) })
                }
            }

            // if the user is restricted to just viewing the POS, we want the device
            // settings to override the url's location
            if (props.currentUser.is_restricted_to_pos) {
                dispatch(switchToLocation(deviceSettings.location.id))
            }
        }

        dispatch(setLocation(props.location))
        dispatch(setLogoutPath(props.logoutPath))
        dispatch(setReturnUrl(props.returnUrl))

        if (!!sessionStorage?.user?.id) {
            dispatch(fetchUser(sessionStorage.user.id)).then(() => {
                const server = localStorage?.servers?.find((s) => s.id === sessionStorage.user.id)

                if (!isSessionLocked && (!enteringDeviceSetupMode || !inDeviceSetupMode) && !!server && !!server?.current_check?.id) {
                    dispatch(fetchCheck(server.current_check.id)).then(() => {
                        dispatch(setCurrentComponent('DefaultMenu'))
                        dispatch(setInitialized())
                    })
                } else {
                    dispatch(setInitialized())
                }
            })
        } else {
            dispatch(setCurrentUser(props.currentUser))
            dispatch(setIsUserRestrictedToPos(props.currentUser.is_restricted_to_pos))

            window.sessionStorage.setItem(ADV_POS_SESSION, JSON.stringify({ user: { id: props.currentUser.id }}))

            const server = localStorage?.servers?.find((s) => s.id === props.currentUser.id)

            if (!props.currentUser.is_session_locked && (!enteringDeviceSetupMode || !inDeviceSetupMode) && !!server && !!server?.current_check?.id) {
                dispatch(fetchCheck(server.current_check.id)).then(() => {
                    dispatch(setCurrentComponent('DefaultMenu'))
                    dispatch(setInitialized())
                })
            } else {
                dispatch(setInitialized())
            }
        }

        scrollHinting.init()
        document.addEventListener('keydown', handleApplicationShortcuts, false)

        return () => {
            scrollHinting.cleanup()
            document.removeEventListener('keydown', handleApplicationShortcuts, false)
        }
    }, [])

    // actions post-user switch/lock
    useEffect(() => {
        if (isInitialized && !!currentUser && !isSessionLocked) {
            fetchDependencies()
        }
    }, [isInitialized, currentUser, isSessionLocked])

    // catch / respond to errors
    useEffect(() => {
        if (hasError) { showBoundary() }
    }, [hasError])

    // respond to component changes
    useEffect(() => {
        if (component !== 'ManageCheck') {
            dispatch(setInMoveItemsMode(false))
            dispatch(configureModal({ modal: 'selectTab', config: { uuids: [], fromTabId: null, toTabId: null } }))
        }

        // relinquish control of full check checkout or single tab checkout
        if (component !== 'Checkout') {
            if (check?.individual_tab_payment_disabled_by_id === currentUser.id) {
                dispatch(enableIndividualTabPayments())
            }

            const lockedTab = (check?.tabs || []).find(tab => tab.payment_locked_by_id === currentUser.id)

            if (lockedTab?.payment_locked_by_id === currentUser.id) {
                dispatch(unlockTabPayments(lockedTab))
            }

            dispatch(resetCheckout())
        }
    }, [component])

    return isSessionLocked && !hasError ? LMFAO : (
        <div
            id='advanced-pos--terminal-container'
            className={isSidebarOpen ? 'sidebar--open' : 'sidebar--closed'}
        >
            {
                !!currentUser && isInitialized && (
                    <Suspense fallback=''>
                        <Navigation {...props} dynamicContent={dynamicContent} />

                        <main id='advanced-pos-terminal--main'>
                            <FormProvider {...formMethods}>
                                { renderComponent }
                            </FormProvider>
                        </main>

                        <GeneralSidebar {...props} />

                        <div id='advanced-pos-terminal--modals'>
                            <AddCardToTabModal />
                            <AddToChitTabModal />
                            <ChangeStaffModal />
                            <CompDetailsModal />
                            <CreateCheckModal transactionsAllowed={props.transactionsAllowed} />
                            <CreateNewTabModal />
                            <EditCardOnTabModal />
                            <EmailReceiptModal />
                            <ReportModal />
                            <ManageCheckModal />
                            <ManageCheckItemModal />
                            <ManageChitItemModal />
                            <PrintOptionModal />
                            <ReceiptModal />
                            <RenameTabModal />
                            <AddMembershipModal />
                            <SelectTabModal />
                            <AlterationModal />
                            <SearchModal />
                            <TransactionsModal />
                        </div>
                    </Suspense>
                )
            }
        </div>
    )
}
