import { createSlice } from "@reduxjs/toolkit"
import axios from 'axios'
import moment from 'moment'

import {
    clearAdyenState,
    setTerminalResponse,
    setProcessing as setTerminalProcessing,
    cancelTerminalTransaction
} from '@/features/Adyen/adyenSlice'

import { addAlert } from '@/features/Notifications/notificationSlice'
import { updateIsSessionLocked, setCurrentUser } from '@/features/Session/sessionSlice'

import {
    PREVENT_LOADER,
    SELECTED_COMPANY,
    SELECTED_LOCATION,
    ADV_POS_SESSION,
    ADYEN_TERMINAL_SALE_ID,
    ADYEN_TERMINAL_SERVICE_ID,
} from '@/lib/Storage'

import { isOpen, inAlteration } from '@/features/AdvancedPointOfSale/lib/Checks'
import { sortByAddedDateTime } from '@/features/AdvancedPointOfSale/lib/Items'
import { unique, sortedByArray } from '@/lib/Array'
import { accessToken } from "@/lib/Csrf"
import { SecureHash } from '@/lib/Crypto'
import { debug } from '@/lib/Debug'

const localStorage = JSON.parse(window.localStorage.getItem(ADV_POS_SESSION))

const selfDispatch = (reducer='', state, payload=null) => {
    advancedPointOfSaleSlice.caseReducers[reducer](state, { payload })
}

const initialCheckAndChitTabs = [
    { id: 1, title: 'Full Check', append: null },
    { id: 2, title: 'Order Queue', append: null },
]

const initialMenuBreadcrumbState = [{
    id: null,
    name: 'All Menus',
    type: 'menu'
}]

const initialMenuContentState = {
    all: [],
    menu: [],
    default: null,
    item: null,
    itemConfig: {},
    loaded: {
        menuPath: [],
        itemId: null,
    },
}

export const popModalDefaults = {
    isOpen: false,
    text: 'Success!',
    icon: 'fa-check bg-green',
    size: 'modal-sm',
    delay: 2500,
    className: '',
}

export const searchModalDefaults = {
    title: 'Menu Search',
    isOpen: false,
    results: null,
}

const initialState = {
    companyId: null,
    locationId: null,

    breadcrumbs: [],
    breadcrumbsToPrepend: [],
    categories: [],
    checks: {
        all: [],
        current: null,
        lastUpdatedAt: null,
    },
    checksInAlteration: {
        all: [],
        lastUpdatedAt: null,
    },
    checklessReservations: [],
    closeOfDay: {
        servers: [],
        serversPendingShiftEnd: [],
    },
    comps: [],
    compReasons: [],
    deviceSettings: localStorage?.device_settings || {
        location: {
            id: null,
            name: null,
        },
        name: null,
        slug: null,
    },
    fulfillmentProviders: [],
    hasVirtualKeyboardApi: false,
    virtualKeyboardIsOpen: false,
    inDeviceSetupMode: false,
    inItemEditMode: false,
    inMoveItemsMode: false,
    isInitialized: false,
    loadedComponent: {
        default: 'OpenChecks',
        current: null,
        previous: null,
    },
    locations: [],
    modals: {
        addCardToTab: {
            isOpen: false,
            size: 'modal-sm',
            tab: null,
            amountToPreAuthorize: 0,
        },
        addToChitTab: {
            isOpen: false,
            size: 'modal-sm',
            duplicateItem: {
                id: null,
                config: null,
            },
        },
        alteration: {
            isOpen: false,
            size: 'modal-md',
            mode: 'create',
            amount: null,
            amount_type: 'debit',
            notes: null,
            tab_id: null,
            towards_type: null,
            uuid: null,
        },
        changeStaff: {
            isOpen: false,
            size: 'modal-md',
            callbacks: {
                afterSuccess: null,
                afterFailure: null,
            }
        },
        createCheck: {
            isOpen: false,
            action: null,
            resourceType: null,
            size: 'modal-md',
            tab: 'custom_name',
            mode: null,
        },
        createNewTab: {
            isOpen: false,
            size: 'modal-md',
        },
        editCardOnTab: {
            isOpen: false,
            size: 'modal-sm',
            checkId: null,
            tabId: null,
            storedCard: null,
            preAuthorizedTransaction: null,
        },
        popModal: popModalDefaults,
        manageCheck: {
            isOpen: false,
            size: 'modal-md',
            check: null,
        },
        manageCheckItem: {
            isOpen: false,
            size: 'modal-md',
            tab: null,
            item: null,
        },
        manageChitItem: {
            isOpen: false,
            size: 'modal-md',
            tab: null,
            item: null,
            isUnavailable: false,
            isModifierUnavailable: false,
            unavailableModifierIds: []
        },
        receipt: {
            isCheckPaid: false,
            isOpen: false,
            forFullCheck: false,
            size: 'modal-md',
            tabs: null,
            tab: null,
        },
        compDetails: {
            isOpen: false,
            size: 'modal-md',
        },
        selectTab: {
            isOpen: false,
            size: 'modal-md',
            uuids: [],
            fromTabId: null,
            toTabId: null,
        },
        emailReceipt: {
            isOpen: false,
            size: 'modal-md',
            tab: null,
            callbacks: {
                afterSuccess: null,
                afterFailure: null,
            }
        },
        renameTab: {
            isOpen: false,
            size: 'modal-md',
            tab: null,
            newName: null,
        },
        addMembership: {
            isOpen: false,
            size: 'modal-sm',
            tab: null,
        },
        report: {
            isOpen: false,
            onClose: null,
            reportType: null,
            data: {},
        },
        printOption: {
            isOpen: false,
            size: 'modal-md',
            title: null,
            data: {},
        },
        search: {
            recentQueries: [],
            ...searchModalDefaults,
        },
        transactions: {
            isOpen: false,
            check: null,
        }
    },
    modifierGroups: [],
    menus: initialMenuContentState,
    printers: [],
    resourceTypes: [],
    checkout: {
        tab: null,
        tipAmount: null,
        paymentMethod: null,
        creditMethod: null,
        amountTowardsBalance: null,
        changeDue: null,
        currentBalance: null,
        balanceRemainingCents: null,
        payFullAmount: true,
        isAmountValid: false,
        processingPayment: false,
        shouldOverProvision: false,
        errors: [],
    },
    sidebars: {
        general: {
            isOpen: false,
        },
        checkAndChit: {
            tabs: initialCheckAndChitTabs,
            tab: initialCheckAndChitTabs[0],
        }
    },
    transactions: {},
    temporary: {},
    users: [],
    showCalculator: false,
}

export const advancedPointOfSaleSlice = createSlice({
    name: 'point_of_sale',
    initialState: initialState,
    reducers: {
        setPreloadedState: (state, action) => {
            state.companyId  = action.payload.companyId
            state.locationId = action.payload.locationId
        },

        addBreadcrumb: (state, action) => {
            state.breadcrumbs = [...state.breadcrumbs, action.payload]
        },
        addCheck: (state, action) => {
            state.checks.all = sortByAddedDateTime([...state.checks.all, action.payload], 'desc', 'created_at')
            state.checks.lastUpdatedAt = Date.now()
        },
        addChitToCheck: (state, action) => {
            const checks = [...state.checks.all]
            const check = checks.find((check) => check.id === action.payload.checkId)
            check.chits.push(action.payload.chit)
            state.checks.all = checks
            state.checks.lastUpdatedAt = Date.now()
        },
        addChitToCurrentCheck: (state, action) => {
            const check = {...state.checks.current}
            if (!check.chits) { check.chits = [] }
            check.chits.push(action.payload)
            state.currentCheck = check
        },
        addOrUpdateCheckInAlteration: (state, action) => {
            // ---------------------------------------------------
            // ACTION 1: try to find and update the
            // target check in the full collection of checks
            // ---------------------------------------------------
            const targetIndex = state.checksInAlteration.all.findIndex((c) => c.id === action.payload.id)

            // add check
            if (targetIndex === -1) {
                state.checksInAlteration.all = sortByAddedDateTime([...state.checksInAlteration.all, action.payload], 'desc', 'last_altered_at')

            // update check
            } else {
                let updatedChecks            = [...state.checksInAlteration.all]
                updatedChecks[targetIndex]   = action.payload
                state.checksInAlteration.all = updatedChecks
            }

            // -----------------------------------------------------
            // ACTION 2: update the current check if the one that
            // was broadcast is the state.checks.current
            // -----------------------------------------------------
            if (!!state.checks.current && state.checks.current.id === action.payload.id) {
                state.checks.current = action.payload
            }

            state.checksInAlteration.lastUpdatedAt = Date.now()
        },
        addOrUpdateCheck: (state, action) => {
            // ---------------------------------------------------
            // ACTION 1: try to find and update the
            // target check in the full collection of checks
            // ---------------------------------------------------
            const targetIndex = state.checks.all.findIndex((c) => c.id === action.payload.id)

            // add check
            if (targetIndex === -1) {
                state.checks.all = sortByAddedDateTime([...state.checks.all, action.payload], 'desc', 'created_at')

            // update check
            } else {
                let updatedChecks          = [...state.checks.all]
                updatedChecks[targetIndex] = action.payload
                state.checks.all           = updatedChecks
            }

            // ---------------------------------------------------
            // ACTION 2: update the current check if the one
            // that was broadcast is the state.checks.current
            // ---------------------------------------------------
            if (!!state.checks.current && state.checks.current.id === action.payload.id) {
                state.checks.current = action.payload
            }

            state.checks.lastUpdatedAt = Date.now()
        },
        afterUserSwitchActions: (state) => {
            state.breadcrumbs             = initialMenuBreadcrumbState
            state.checks.all              = []
            state.checks.current          = null
            state.checks.lastUpdatedAt    = null
            state.checklessReservations   = []
            state.loadedComponent.current = state.loadedComponent.default
            state.menus                   = initialMenuContentState
            state.resourceTypes           = []
        },
        closeModal: (state, action) => {
            if (!state.modals[action.payload]) {
                if (console) { console.error(`closeModal: "${action.payload}" is unknown`) }
                return
            }

            if (state.modals[action.payload].isOpen) {
                state.modals[action.payload] = initialState.modals[action.payload]
            }
        },
        closeSidebar: (state) => {
            state.sidebars.general.isOpen = false
        },
        configureModal: (state, action) => {
            const modal = action.payload.modal

            if (!!state.modals?.[modal]) {
                state.modals[modal] = {
                    ...state.modals[modal],
                    ...action.payload.config
                }
            }
        },
        loadDefaultComponent: (state) => {
            state.loadedComponent.current = null
        },
        openModal: (state, action) => {
            if (!!state.modals[action.payload]) {
                state.modals[action.payload].isOpen = true
            } else {
                if (console) { console.error(`openModal: "${action.payload}" is unknown`) }
            }
        },
        openSidebar: (state) => {
            state.isSidebarOpen = true
        },
        openSidebarTab: (state, action) => {
            switch(true) {
                // open a tab by passing in a tab object
                case (!!state.sidebars[action.payload.sidebar] && !!action.payload?.tab) :
                    state.sidebars[action.payload.sidebar].tab = action.payload.tab
                    break

                // open a tab via its index of the existing tabs array in state
                case (!!state.sidebars[action.payload.sidebar] && !!action.payload?.tabIndex) :
                    state.sidebars[action.payload.sidebar].tab = state.sidebars[action.payload.sidebar].tabs[action.payload.tabIndex]
                    break

                default :
                    if (console) { console.error(`openSidebarTab: "${action.payload.sidebar}" is unknown`) }
            }
        },
        toggleSidebar: (state) => {
            state.sidebars.general.isOpen = !state.sidebars.general.isOpen
        },
        truncateBreadcrumbs: (state, action) => {
            const copy = [...state.breadcrumbs]
            let target = null

            // first try looking by menu_uuid
            if (!!action.payload.menu_uuid) {
                target = copy.find((crumb) => crumb.menu_uuid === action.payload.menu_uuid && crumb.type === action.payload.type)
            }

            // if that doesn't find it, try looking by ID for the menu
            if (!!action.payload.id) {
                target = copy.find((crumb) => crumb.id === action.payload.id && crumb.type === action.payload.type)
            }

            if (!!target && copy.indexOf(target) !== -1) {
                state.breadcrumbs = copy.slice(0, (copy.indexOf(target) + 1))
            }
        },
        updateChit: (state, action) => {
            const targetIndex = state.checks.current.chits.findIndex((chit) => chit.id === action.payload.id)

            if (targetIndex !== -1) {
                state.checks.current.chits[targetIndex] = action.payload
            } else {
                if (console) { console.error('Unable to find the chit when attempting to update it!', action.payload) }
            }
        },
        removeCheckInAlteration: (state, action) => {
            state.checksInAlteration.all = state.checksInAlteration.all.filter(({ id }) => id !== action.payload.id)

            if (state.checks.current?.id === action.payload.id) {
                selfDispatch('setCurrentComponent', state, 'ChecksInAlteration')
                selfDispatch('resetCurrentCheck', state)

                selfDispatch('configureModal', state, {
                    modal: 'popModal',
                    config: {
                        text: 'Check Has Been Re-Closed',
                        size: 'modal-md',
                        icon: 'fa-xmark bg-danger',
                        delay: 5000
                    }
                })
                selfDispatch('openModal', state, 'popModal')
            }
        },
        updateCheckLockState: (state, action) => {
            const { check_id:checkId, server_id:serverId } = action.payload

            let updatedChecks = [...state.checks.all]
            const checkIndex  = updatedChecks.findIndex(({ id }) => id === checkId)

            if (checkIndex !== undefined && checkIndex !== -1) {
                updatedChecks[checkIndex].individual_tab_payment_disabled_by_id = serverId
                state.checks.all = updatedChecks

                if (!!state.checks.current && state.checks.current.id === checkId) {
                    let updatedCheck = {...state.checks.current}
                    updatedCheck.individual_tab_payment_disabled_by_id = serverId
                    state.checks.current = updatedCheck
                }

                state.checks.lastUpdatedAt = Date.now()
            }
        },
        updateTabLockState: (state, action) => {
            const { check_id:checkId, tab_id:tabId, server_id:serverId } = action.payload

            if (!!state.checks.current && state.checks.current.id === checkId) {
                const updatedCheck = {...state.checks.current}
                const tabIndex     = updatedCheck.tabs?.findIndex(({ id }) => id === tabId)

                if (tabIndex !== undefined && tabIndex !== -1) {
                    updatedCheck.tabs[tabIndex].payment_locked_by_id = serverId
                    state.checks.current = updatedCheck
                }
            }
        },

        setBreadcrumbsToPrepend: (state, action) => {
            state.breadcrumbsToPrepend = action.payload
        },
        setCategories: (state, action) => {
            state.categories = action.payload
        },
        setAllChecks: (state, action) => {
            state.checks.all = action.payload
            state.checks.lastUpdatedAt = Date.now()
        },
        setChecksInAlteration: (state, action) => {
            state.checksInAlteration.all           = action.payload
            state.checksInAlteration.lastUpdatedAt = Date.now()
        },
        setChecklessReservations: (state, action) => {
            state.checklessReservations = action.payload
        },
        setCurrentCheck: (state, action) => {
            const currentTime = Date.now()

            // always store the current check ID in localStorage
            // so that we can restore it later if necessary
            const existingData = JSON.parse(window.localStorage.getItem(ADV_POS_SESSION)) || {}
            const servers      = [...(existingData?.servers || [])]
            const serverIndex  = servers.findIndex((s) => s.id === action.payload.server.id)

            const payload = {
                id: action.payload.server.id,
                current_check: {
                    id: action.payload.id,
                    lastUpdatedAt: currentTime,
                },
            }

            if (serverIndex !== -1) {
                servers[serverIndex] = payload
            } else {
                servers.push(payload)
            }

            window.localStorage.setItem(ADV_POS_SESSION, JSON.stringify({ ...existingData, servers }))

            state.checks.current = action.payload
        },
        setCurrentComponent: (state, action) => {
            if (inAlteration(state.checks.current) && /^(DefaultMenu)$/i.test(action.payload)) { return }

            if (state.loadedComponent.current === action.payload) { return }

            if (/menu/i.test(state.loadedComponent.current) && !/menu/i.test(action.payload)) {
                state.breadcrumbs = []
            }

            state.loadedComponent.previous = state.loadedComponent.current
            state.loadedComponent.current  = action.payload

            // If we're simply switching to another component, and not unmounting OpenChecks to
            // show the lock screen, then update the timestamp to now so skipping a checks fetch
            // may be possible if the user quickly switches back before the timeout period is over
            if (/OpenChecks/i.test(state.loadedComponent.current) && !!state.checks.lastUpdatedAt) {
                state.checks.lastUpdatedAt = Date.now()
            }
        },
        setDeviceSettings: (state, action) => {
            state.deviceSettings = action.payload
        },
        setDeviceSetupMode: (state, action) => {
            if (action.payload === true) {
                state.inDeviceSetupMode       = true
                state.loadedComponent.current = 'DeviceSetup'
            }
        },
        setFulfillmentProviders: (state, action) => {
            state.fulfillmentProviders = action.payload
        },
        setHasVirtualKeyboardApi: (state, action) => {
            state.hasVirtualKeyboardApi = action.payload
        },
        setVirtualKeyboardIsOpen: (state, action) => {
            state.virtualKeyboardIsOpen = action.payload
        },
        setInitialized: (state) => {
            state.isInitialized = true
        },
        setItemDefaultConfig: (state, action) => {
            const item = action.payload

            const defaultModifiers = item.default_modifiers.map(modifier => {
                if (modifier.is_available) {
                    return modifier
                } else {
                    return { ...modifier, chit_action: 'unavailable' }
                }
            })

            state.menus.itemConfig = {
                modifiers: defaultModifiers
            }
        },
        setItemConfig: (state, action) => {
            state.menus.itemConfig = action.payload
        },
        setItemEditMode: (state, action) => {
            state.inItemEditMode = action.payload
        },
        setLoadedItemId: (state, action) => {
            state.menus.loaded.itemId = action.payload
        },
        setLoadedMenuPath: (state, action) => {
            if (action.payload.action === 'push') {
                state.menus.loaded.menuPath = [
                    ...state.menus.loaded.menuPath,
                    action.payload.payload
                ]
            }

            if (action.payload.action === 'replace') {
                const newPath = action.payload.payload.split(/[[\]]{1,2}/)
                                                      .slice(2, -1)
                                                      .map((piece) => `[${piece}]`)

                state.menus.loaded.menuPath = newPath
            }

            if (action.payload.action === 'fully_replace') {
                state.menus.loaded.menuPath = action.payload.payload
            }
        },
        setLocations: (state, action) => {
            state.locations = action.payload
        },
        setAllMenus: (state, action) => {
            state.menus.all = action.payload
        },
        setMenuContent: (state, action) => {
            state.menus.menu = action.payload
        },
        setMenuItem: (state, action) => {
            state.menus.item = action.payload
        },
        setModifierGroups: (state, action) => {
            state.modifierGroups = action.payload
        },
        setPrinters: (state, action) => {
            state.printers = action.payload
        },
        setResourceTypes: (state, action) => {
            state.resourceTypes = action.payload
        },
        setCheckoutTab: (state, action) => {
            state.checkout.tab = action.payload
        },
        setCheckoutErrors: (state, action) => {
            switch(true) {
                case action.payload === 'clear' || action.payload === null :
                    state.checkout.errors = []
                    break

                case typeof action.payload === 'string' :
                    state.checkout.errors = [...state.checkout.errors, ...[action.payload]]
                    break

                case typeof action.payload === 'object' && Array.isArray(action.payload) :
                    state.checkout.errors = [...state.checkout.errors, ...action.payload]
                    break

                default :
                    if (console) { console.error('Unsupported type when setting checkout errors!') }
            }
        },
        setTipAmount: (state, action) => {
            state.checkout.tipAmount = action.payload
        },
        setPaymentMethod: (state, action) => {
            state.checkout.paymentMethod = action.payload
        },
        setChangeDue: (state, action) => {
            state.checkout.changeDue = action.payload
        },
        setAmountTowardsBalance: (state, action) => {
            state.checkout.amountTowardsBalance = action.payload
        },
        setPayFullAmount: (state, action) => {
            state.checkout.payFullAmount = action.payload
        },
        setCurrentBalance: (state, action) => {
            state.checkout.currentBalance = action.payload
        },
        setBalanceRemainingCents: (state, action) => {
            state.checkout.balanceRemainingCents = action.payload
        },
        setIsAmountValid: (state, action) => {
            state.checkout.isAmountValid = action.payload
        },
        setCreditMethod: (state, action) => {
            state.checkout.creditMethod = action.payload
        },
        setShouldOverProvision: (state, action) => {
            state.checkout.shouldOverProvision = action.payload
        },
        setSidebarTabs: (state, action) => {
            state.sidebars[action.payload.sidebar].tabs = action.payload.tabs

            // allow a dev to pass in a tab to immediately
            // switch to as the tabs are set/updated
            if (!!action.payload?.tab) {
                state.sidebars[action.payload.sidebar].tab = action.payload.tab
            }
        },
        // @note: This only accepts objects or NULL to clear it out
        setTemporary: (state, action) => {
            // clear out the state
            if (action.payload === undefined || action.payload === null) {
                state.temporary = {}
                return
            }

            // set an object while respecting the current contents by doing it via a merge
            if (typeof action.payload === 'object' && !Array.isArray(action.payload)) {
                state.temporary = {...state.temporary, ...action.payload}
                return
            }

            if (console) { console.error('Could not set temporary data') }
        },
        setTransactions: (state, action) => {
            if (action.payload === 'clear') {
                state.transactions = {}
                return
            }

            if (state.modals.transactions?.check?.id !== action.payload.check_id) { return }

            switch(action.payload.action) {
                case 'replaceAll' :
                    state.transactions = action.payload.transactions
                    break

                case 'insertOrUpdate' :
                    const group                    = action.payload.group
                    const existingTransactionIndex = state.transactions[group].findIndex(({ id }) => id === action.payload.transaction.id)
                    const existingTransaction      = state.transactions[group][existingTransactionIndex]
                    const updatedTransactions      = {...state.transactions}

                    if (!!existingTransaction) {
                        updatedTransactions[group][existingTransactionIndex] = action.payload.transaction
                    } else {
                        updatedTransactions[group].push(action.payload.transaction)
                    }

                    state.transactions = updatedTransactions

                    state.transactions['total_found'] = (
                        (updatedTransactions?.booking_payments || []).length
                        + (updatedTransactions?.check_payments || []).length
                        + (updatedTransactions?.tab_payments || []).length
                    )
                    break
            }
        },
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setPrinters: (state, action) => {
            state.printers = action.payload
        },
        setCompReasons: (state, action) => {
            state.compReasons = action.payload
        },
        setComps: (state, action) => {
            state.comps = action.payload
        },
        setInMoveItemsMode: (state, action) => {
            state.inMoveItemsMode = action.payload
        },
        setAllServers: (state, action) => {
            state.closeOfDay.servers = sortedByArray(unique(action.payload, 'id'), 'asc', 'name')
        },
        setServersPendingShiftEnd: (state, action) => {
            state.closeOfDay.serversPendingShiftEnd = sortedByArray(unique(action.payload, 'id'), 'asc', 'name')
        },
        setShowCalculator: (state, action) => {
            state.showCalculator = action.payload
        },

        resetChecks: (state) => {
            state.checks.all                       = []
            state.checks.lastUpdatedAt             = null
            state.checksInAlteration.all           = []
            state.checksInAlteration.lastUpdatedAt = null
        },
        resetCheckAndChitSidebar: (state) => {
            const selectedTabId              = state.sidebars.checkAndChit.tab.id
            state.sidebars.checkAndChit.tabs = initialCheckAndChitTabs
            state.sidebars.checkAndChit.tab  = initialCheckAndChitTabs.find((tab) => tab.id === selectedTabId)
        },
        resetChecklessReservations: (state) => {
            state.checklessReservations = []
        },
        resetComps: (state) => {
            state.comps = []
        },
        resetCurrentCheck: (state) => {
            state.checks.current = null
        },
        resetMenuLoadedPath: (state) => {
            state.menus.loaded.menuPath = []
        },
        resetMenuBreadcrumbs: (state) => {
            state.breadcrumbs = initialMenuBreadcrumbState
        },
        resetMenuContent: (state) => {
            state.menus.menu            = initialMenuContentState.menu
            state.menus.default         = initialMenuContentState.default
            state.menus.loaded.menuPath = initialMenuContentState.loaded.menuPath
        },
        resetMenuItem: (state) => {
            state.menus.item          = null
            state.menus.itemConfig    = {}
            state.menus.loaded.itemId = null
        },
        resetResourceTypes: (state) => {
            state.resourceTypes = []
        },
        resetCheckout: (state) => {
            state.checkout = {
                tab: null,
                tipAmount: null,
                paymentMethod: null,
                creditMethod: null,
                amountTowardsBalance: null,
                currentBalance: null,
                payFullAmount: true,
                isAmountValid: false,
                shouldOverProvision: false,
            }
        },
        setModalAttribute: (state, action) => {
            state.modals[action.payload.modal][action.payload.attribute] = action.payload.value
        },
    }
})

export const {
    setPreloadedState,

    addBreadcrumb,
    addCheck,
    addChitToCheck,
    addChitToCurrentCheck,
    addOrUpdateCheck,
    addOrUpdateCheckInAlteration,
    afterUserSwitchActions,
    closeModal,
    closeSidebar,
    configureModal,
    loadDefaultComponent,
    openModal,
    openSidebar,
    openSidebarTab,
    toggleSidebar,
    truncateBreadcrumbs,
    updateChit,
    removeCheckInAlteration,
    updateCheckLockState,
    updateTabLockState,

    setBreadcrumbsToPrepend,
    setCategories,
    setAllChecks,
    setChecksInAlteration,
    setChecklessReservations,
    setCurrentCheck,
    setCurrentComponent,
    setDeviceSettings,
    setDeviceSetupMode,
    setFulfillmentProviders,
    setHasVirtualKeyboardApi,
    setVirtualKeyboardIsOpen,
    setInitialized,
    setItemDefaultConfig,
    setItemEditMode,
    setItemConfig,
    setLoadedMenuPath,
    setLoadedItemId,
    setLocations,
    setAllMenus,
    setMenuContent,
    setMenuItem,
    setModifierGroups,
    setPrinters,
    setResourceTypes,
    setCheckoutTab,
    setCheckoutErrors,
    setTipAmount,
    setChangeDue,
    setPaymentMethod,
    setShouldOverProvision,
    setSidebarTabs,
    setTemporary,
    setTransactions,
    setUpdatedChit,
    setUsers,
    setCompReasons,
    setComps,
    setInMoveItemsMode,
    setAllServers,
    setServersPendingShiftEnd,

    resetChecks,
    resetCheckAndChitSidebar,
    resetChecklessReservations,
    resetComps,
    resetCurrentCheck,
    resetMenuBreadcrumbs,
    resetMenuContent,
    resetMenuItem,
    resetMenuLoadedPath,
    resetResourceTypes,
    resetCheckout,

    setModalAttribute,
    setAmountTowardsBalance,
    setPayFullAmount,
    setCreditMethod,
    setCurrentBalance,
    setBalanceRemainingCents,
    setIsAmountValid,
    setPaymentProcessing,
    setShowCalculator,
} = advancedPointOfSaleSlice.actions

export const selectCompanyId                       = state => state.point_of_sale.companyId
export const selectLocationId                      = state => state.point_of_sale.locationId
export const selectBreadcrumbs                     = state => state.point_of_sale.breadcrumbs
export const selectCategories                      = state => state.point_of_sale.categories
export const selectAllChecks                       = state => state.point_of_sale.checks.all
export const selectAllClosedChecks                 = state => state.point_of_sale.checks.all.filter((check) => !isOpen(check))
export const selectAllOpenChecks                   = state => state.point_of_sale.checks.all.filter((check) => isOpen(check))
export const selectCurrentCheck                    = state => state.point_of_sale.checks.current
export const selectChecksLastUpdatedAt             = state => state.point_of_sale.checks.lastUpdatedAt
export const selectChecksInAlteration              = state => state.point_of_sale.checksInAlteration.all
export const selectCurrentCheckInAlteration        = state => state.point_of_sale.checksInAlteration.current
export const selectChecksInAlterationLastUpdatedAt = state => state.point_of_sale.checksInAlteration.lastUpdatedAt
export const selectCheckslessReservations          = state => state.point_of_sale.checklessReservations
export const selectAllServers                      = state => state.point_of_sale.closeOfDay.servers
export const selectServersPendingShiftEnd          = state => state.point_of_sale.closeOfDay.serversPendingShiftEnd
export const selectComponent                       = state => state.point_of_sale.loadedComponent.current || state.point_of_sale.loadedComponent.default
export const selectPreviousComponent               = state => state.point_of_sale.loadedComponent.previous
export const selectDeviceSettings                  = state => state.point_of_sale.deviceSettings
export const selectFulfillmentProviders            = state => state.point_of_sale.fulfillmentProviders
export const selectHasVirtualKeyboardApi           = state => state.point_of_sale.hasVirtualKeyboardApi
export const selectVirtualKeyboardIsOpen           = state => state.point_of_sale.virtualKeyboardIsOpen
export const selectInDeviceSetupMode               = state => state.point_of_sale.inDeviceSetupMode
export const selectInItemEditMode                  = state => state.point_of_sale.inItemEditMode
export const selectInMoveItemsMode                 = state => state.point_of_sale.inMoveItemsMode
export const selectIsInitialized                   = state => state.point_of_sale.isInitialized
export const selectIsSidebarOpen                   = state => state.point_of_sale.sidebars.general.isOpen
export const selectItemConfig                      = state => state.point_of_sale.menus.itemConfig
export const selectLoadedItemId                    = state => state.point_of_sale.menus.loaded.itemId
export const selectLoadedMenuPath                  = state => state.point_of_sale.menus.loaded.menuPath.join('')
export const selectLocations                       = state => state.point_of_sale.locations
export const selectAllMenus                        = state => state.point_of_sale.menus.all
export const selectMenuContent                     = state => state.point_of_sale.menus.menu
export const selectMenuItem                        = state => state.point_of_sale.menus.item
export const selectModals                          = state => state.point_of_sale.modals
export const selectModifierGroups                  = state => state.point_of_sale.modifierGroups
export const selectPrinters                        = state => state.point_of_sale.printers
export const selectResourceTypes                   = state => state.point_of_sale.resourceTypes
export const selectUpdatedChit                     = state => state.point_of_sale.updatedChit
export const selectUsers                           = state => state.point_of_sale.users
export const selectCompReasons                     = state => state.point_of_sale.compReasons
export const selectComps                           = state => state.point_of_sale.comps
export const selectCheckoutTab                     = state => state.point_of_sale.checkout.tab
export const selectCheckoutErrors                  = state => state.point_of_sale.checkout.errors
export const selectCheckoutMode                    = state => state.point_of_sale.checkout.tab === null ? 'full-check' : 'single-tab'
export const selectChangeDue                       = state => state.point_of_sale.checkout.changeDue
export const selectTipAmount                       = state => state.point_of_sale.checkout.tipAmount
export const selectPaymentMethod                   = state => state.point_of_sale.checkout.paymentMethod
export const selectProcessingPayment               = state => state.point_of_sale.checkout.processingPayment
export const selectAmountTowardsBalance            = state => state.point_of_sale.checkout.amountTowardsBalance
export const selectCurrentBalance                  = state => state.point_of_sale.checkout.currentBalance
export const selectBalanceRemainingCents           = state => state.point_of_sale.checkout.balanceRemainingCents
export const selectIsAmountValid                   = state => state.point_of_sale.checkout.isAmountValid
export const selectPayFullAmount                   = state => state.point_of_sale.checkout.payFullAmount
export const selectCreditMethod                    = state => state.point_of_sale.checkout.creditMethod
export const selectShouldOverProvision             = state => state.point_of_sale.checkout.shouldOverProvision
export const selectSidebars                        = state => state.point_of_sale.sidebars
export const selectTemporary                       = state => state.point_of_sale.temporary
export const selectShowCalculator                  = state => state.point_of_sale.showCalculator
export const selectTransactions                    = state => state.point_of_sale.transactions

// the keys of the object must be alphabetical because fingerprint
// comparisons are done using JSON.stringify which is alpha-sensitive
export const selectDeviceFingerPrint = (state) => {
    try {
        return {
            location_id: state.point_of_sale.deviceSettings.location.id,
            name: state.point_of_sale.deviceSettings.name,
            slug: state.point_of_sale.deviceSettings.slug,
        }
    } catch(e) {
        if (debug && console) { console.log('Could not determine device fingerprint', e) }
        return null
    }
}

export const selectCurrentCheckBookingTab = (state) => {
    const currentCheck = state.point_of_sale.checks.current

    return !!currentCheck
        ? currentCheck.tabs.find((tab) => tab.is_booking)
        : { items: [] }
}

export const selectCurrentCheckGeneralTab = (state) => {
    const currentCheck = state.point_of_sale.checks.current

    return !!currentCheck && !!currentCheck?.tabs
        ? currentCheck.tabs.find((tab) => tab.is_general)
        : { items: [] }
}

export const selectCurrentChit = (state) => {
    const currentCheck  = selectCurrentCheck(state)
    const currentUserId = state.session.currentUser.id

    return (currentCheck?.chits || []).find((chit) => chit.server?.id === currentUserId && !chit.fulfilled_at)
}

export const selectCurrentChitGeneralTab = (state) => {
    const currentCheck = state.point_of_sale.checks.current
    const currentChit  = currentCheck?.chits?.[currentCheck?.chits?.length - 1]

    return !!currentChit && !!currentChit?.tabs
        ? currentChit.tabs.find((tab) => tab.is_general)
        : { items: [] }
}

export function toggleMenuSearch(action=null) {
    return (dispatch, getState) => {
        const modal = getState().point_of_sale.modals.search

        if (action === 'open') {
            dispatch(configureModal({
                modal: 'search',
                config: { ...searchModalDefaults,
                    isOpen: true,
                }
            }))
            dispatch(openModal('search'))
        }

        if (action === 'close') {
            dispatch(closeModal('search'))
            dispatch(configureModal({
                modal: 'search',
                config: { ...searchModalDefaults,
                    recentQueries: modal.recentQueries,
                    isOpen: false,
                }
            }))
        }
    }
}

export function loadMenuItem({ breadcrumbs=null, item=null, menu_id=null, menu_path=null }) {
    return (dispatch) => {
        if (!!breadcrumbs && !!item && !!menu_id && !!menu_path && Array.isArray(breadcrumbs)) {
            dispatch(resetMenuBreadcrumbs())

            dispatch(setBreadcrumbsToPrepend(
                breadcrumbs.map((breadcrumb) => ({
                    id: breadcrumb.id,
                    name: breadcrumb.name,
                    type: breadcrumb.type,
                    menu_id: menu_id,
                    path: breadcrumb.path,
                    button_color: breadcrumb.button_color,
                }))
            ))

            dispatch(setLoadedItemId(item.id))
            dispatch(setLoadedMenuPath({ action: 'fully_replace', payload: menu_path }))
            dispatch(setCurrentComponent('MenuItem'))
        }
    }
}

export function addItemToChitTabs(chitId, itemCounts, duplicateItem=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const itemId     = duplicateItem?.id || getState().point_of_sale.menus.item.id
        const itemConfig = duplicateItem?.config || getState().point_of_sale.menus.itemConfig

        const url = `/companies/${companyId}/locations/${locationId}/pos/chits/${chitId}/add_item_to_tabs`

        return axios.patch(url, {
            authenticity_token: accessToken,
            item_id: itemId,
            item_config: itemConfig,
            item_counts: JSON.stringify(itemCounts),
            device_fingerprint: selectDeviceFingerPrint(getState()),
        })
        .then(({ data }) => {
            if (data.success) {
                dispatch(updateChit(data.payload))
                dispatch(openSidebarTab({ sidebar: 'checkAndChit', tabIndex: 1 }))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function changeStaffForCheck(checkId=null, staffId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const callbacks  = getState().point_of_sale.modals.changeStaff.callbacks

        if (!companyId || !locationId || !checkId || !staffId) { return false }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/change_staff`

        return axios.post(url, {
            authenticity_token: accessToken,
            user_id: staffId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                if (!!callbacks.afterSuccess) { callbacks.afterSuccess() }
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
                if (!!callbacks.afterFailure) { callbacks.afterFailure() }
            }
        }).catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to change server!' }))
        })
    }
}

export function closeLocationDay() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/terminal/close_day`

        return axios.post(url, {
            authenticity_token: accessToken,
            user_id: userId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(closeSidebar())

                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: 'Day Closed!', icon: 'fa-store-lock bg-green' }
                }))

                dispatch(openModal('popModal'))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function createCheck(options={}) {
    return async (dispatch, getState) => {
        options = {
            loadDefaultMenuOnSuccess: true,
            returnToMenuItemOnSuccess: false,
            closeModals: true,
            ...options
        }

        const currentUserId     = options?.check?.server_id || getState().session.currentUser.id
        const companyId         = options?.companyId  || getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId        = options?.locationId || getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const deviceFingerPrint = options.deviceFingerPrint || selectDeviceFingerPrint(getState())
        const term              = getState().adyen.terminalResponse

        // point_of_sale will not be present if this is called via Calendar "send to pos" button
        const newCheck = getState()?.point_of_sale?.modals?.createCheck

        if (!accessToken || !currentUserId || !companyId || !locationId) {
            if (debug && console) { console.log(accessToken, currentUserId, companyId, locationId) }
            return dispatch(addAlert({ type: 'error', text: 'Unable to create check. Missing information.' }))
        }

        // throw an error if we are submitting on swipe card with no terminal response
        if (!!newCheck && newCheck.tab === 'swipe_card' && !term) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to create check. Please swipe card.' }))
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks`

        let params = {
            authenticity_token: accessToken,
            check: { server_id: currentUserId },
            terminal_response: term,
        }

        if (!!options?.check) {
            params = { ...params, check: {
                ...params.check,
                ...options.check,
            }}
        }

        if (!!options?.resourceType?.id) {
            params = { ...params, check: {
                ...params.check,
                resource_type_id: options.resourceType.id,
            }}
        }

        if (!!options?.booking) {
            params = { ...params, check: {
                ...params.check,
                booking_id: options.booking.id,
            }}
        }

        if (!!options?.user_id) {
            params = { ...params, user_id: options.user_id }
        }

        if (debug && console) {
            console.log(
                `%c${window.atob("WW91IGJldHRlciBjaGVjayB5bycgc2VsZiBiZWZvcmUgeW91IHdyZWNrIHlvJyBzZWxm")}`,
                'color: #454545; background: orange; font-size: 14px'
            )
        }

        return axios.post(url, params).then(({ data }) => {
            if (data.success) {
                // close any modals that need closing first
                if (options?.closeModals) {
                    dispatch(closeModal('createCheck'))
                }

                dispatch(setCurrentCheck(data.payload))
                dispatch(createChitForCheck(data.payload.id, data.payload.server.id, deviceFingerPrint, companyId, locationId))

                if (options?.returnToMenuItemOnSuccess) {
                    dispatch(setLoadedMenuPath({ action: 'replace', payload: options.menuPath }))
                    dispatch(setLoadedItemId(options.itemId))
                    dispatch(setItemConfig(options.itemConfig))
                    dispatch(setCurrentComponent('MenuItem'))
                }

                if (options?.loadDefaultMenuOnSuccess && !options?.returnToMenuItemOnSuccess) {
                    dispatch(fetchMenuContent(null, { loadDefault: true })).then(() => {
                        dispatch(setCurrentComponent('DefaultMenu'))
                    })
                }

                dispatch(setTerminalResponse(null)) // null out adyen terminal response
                dispatch(addAlert({ type: 'success', text: options?.successMessage || data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data?.message || 'Unable to create POS check' }))
            }

            return data
        })
    }
}

export function createChitForCheck(checkId=null, _serverId=null, _deviceFingerPrint=null, _companyId=null, _locationId=null) {
    return async (dispatch, getState) => {
        const companyId         = _companyId  || getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId        = _locationId || getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const serverId          = _serverId || getState().session.currentUser.id
        const deviceFingerPrint = _deviceFingerPrint || selectDeviceFingerPrint(getState())

        if (!companyId || !locationId || !checkId || !serverId || !deviceFingerPrint) {
            dispatch(addAlert({ type: 'error', text: 'Could not create the chit!' }  ))
            return
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/chits`

        return axios.post(url, {
            authenticity_token: accessToken,
            check_id: checkId,
            server_id: serverId,
            device_fingerprint: deviceFingerPrint,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addChitToCurrentCheck(data.payload))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function createTabForCheck(customer={}, existingProfile=null, membershipId=null, preAuthorizedPaymentId=null) {
    return async (dispatch, getState) => {
        const companyId        = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId       = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const checkId          = getState().point_of_sale.checks.current?.id
        const terminalResponse = getState().adyen.terminalResponse
        const { name, email }  = customer

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/tabs`

        if (!checkId || !name) {
            dispatch(addAlert({ type: 'error', text: 'Could not create the tab!' }))
            return
        }

        return axios.post(url, {
            authenticity_token: accessToken,
            check_id: checkId,
            terminal_response: existingProfile || terminalResponse,
            name,
            email,
            membershipId,
            pre_authorized_payment_id: preAuthorizedPaymentId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                dispatch(setTerminalResponse(null)) // null out adyen terminal response
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
            return data
        })
    }
}

export function createOrUpdateAlteration(params={}) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const checkId    = params?.check_id
        const tabId      = params?.tab_id
        const userId     = getState().session.currentUser.id

        if (!userId || !checkId || !tabId || !companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Could not create or update check alteration!' }  ))
            return
        } else {
            delete params.check_id
            delete params.tab_id
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/tabs/${tabId}/alteration`

        return axios.patch(url, {
            authenticity_token: accessToken,
            payload: {
                user_id: userId,
                ...params
            },
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function deleteAlteration(params={}) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const checkId    = params?.check_id
        const tabId      = params?.tab_id
        const userId     = getState().session.currentUser.id

        if (!userId || !checkId || !tabId || !companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Could not delete the check alteration!' }  ))
            return
        } else {
            delete params.check_id
            delete params.tab_id
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/tabs/${tabId}/alteration`

        return axios.patch(url, {
            authenticity_token: accessToken,
            payload: {
                mode: 'delete',
                user_id: userId,
                ...params,
            }
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
            return data
        })
    }
}

export function closeCheck() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const checkId    = getState().point_of_sale.checks.current?.id

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/close`

        return axios.patch(url, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(loadDefaultComponent())
                dispatch(resetCurrentCheck())
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function closeTab(tab=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const checkId    = getState().point_of_sale.checks.current?.id

        if (!companyId || !locationId || !checkId || !tab) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to close the tab' }))
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/tabs/${tab.id}/close`

        return axios.patch(url, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function fetchBookingPriceBreakdown(bookingId=null, options={}) {
    return async (dispatch) => {
        let url    = `/bookings/${bookingId}/price_breakdown`
        let params = []

        for (const key in options) {
            params.push(`${key}=${options[key]}`)
        }

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`
        }

        return axios(url).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data?.message || 'Unable to fetch booking pricing breakdown!' }))
            }
            return data
        }).catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to fetch booking pricing breakdown!' }))
        })
    }
}

export function fetchPrinters({ unlessPopulated=false }) {
    return async (dispatch, getState) => {
        if (unlessPopulated && Object.values(getState().point_of_sale.printers).length > 0) { return }

        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/printers`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setPrinters(data.payload))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchPrinter(printerId=null) {
    return async (dispatch, getState) => {
        const companyId   = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId  = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const allPrinters = getState().point_of_sale.printers

        const url = `/companies/${companyId}/locations/${locationId}/pos/printers/${printerId}`

        if (!printerId) {
            dispatch(addAlert({ type: 'error', text: 'Cannot check printer status without a printer ID!' }))
            return
        }

        return axios(url).then(({ data }) => {
            if (data.success) {
                // find and make copies of the data we need to manipulate
                const updatedPrinters    = {...allPrinters}
                const updatedPrinterType = [...updatedPrinters[data.payload.printer_type]]
                const index              = updatedPrinters[data.payload.printer_type].findIndex((p) => p.id === data.payload.id)

                // manipulate the data with the updated results
                updatedPrinterType[index]                  = data.payload
                updatedPrinters[data.payload.printer_type] = updatedPrinterType

                dispatch(setPrinters(updatedPrinters))

                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchCategories() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/categories`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setCategories(data.payload.categories))
                return data.payload.categories
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchCheck(checkId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to fetch the requested check' }))
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setCurrentCheck(data.payload))
                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
                return false
            }
        })
    }
}

export function fetchChecks(serverId=null, options={}) {
    return async (dispatch, getState) => {
        const companyId = getState().point_of_sale.companyId || window.localStorage.getItem(SELECTED_COMPANY)
        const location  = getState()?.location?.location

        if (!companyId || !location.id || !location.time_zone) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to fetch checks!' }))
        }

        let url    = `/companies/${companyId}/locations/${location.id}/pos/checks`
        let params = []

        if (!!serverId) {
            params.push(`server=${serverId}`)
        }

        if (options?.include_closed) {
            params.push(`include_closed=${true}`)
        }

        if (options?.include_payments) {
            params.push(`include_payments=${true}`)
        }

        if (options?.include_searchable_cards) {
            params.push(`include_searchable_cards=${true}`)
        }

        if (options?.exclude_card_on_file) {
            params.push(`exclude_card_on_file=${true}`)
        }

        if (options?.exclude_check_readiness) {
            params.push(`exclude_check_readiness=${true}`)
        }

        if (params.length > 0) {
            url = `${url}?${params.join('&')}`
        }

        return axios({
            url: url,
            method: 'get',
            timeout: 300000,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setAllChecks(data.payload))

                dispatch(setAllServers(data.payload.map(check => check.server)))

                dispatch(setServersPendingShiftEnd(
                    data.payload
                        .map(check => check.server)
                        .filter(server => (
                            moment(server.shift_ended_at).isSameOrBefore(moment(location.adv_pos_day_closed_at)))
                            || server.shift_ended_at === null
                        )
                ))

                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchChecksInAlteration({ unlessPopulated=false }) {
    return async (dispatch, getState) => {
        if (!/^(super|company_admin|manager)$/i.test(getState().session.currentUser.role)) { return }
        if (unlessPopulated && Object.values(getState().point_of_sale.checksInAlteration.all).length > 0) { return }

        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/in_alteration`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setChecksInAlteration(data.payload))
                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchCheckPayments(checkId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/payments`

        return axios(url).then(({ data }) => {
            if (data.success) {
                if (data.payload.transactions.total_found > 0) {
                    dispatch(configureModal({
                        modal: 'transactions',
                        config: { check: data.payload.check }
                    }))
                    dispatch(setTransactions({
                        transactions: data.payload.transactions,
                        check_id: data.payload.check.id,
                        action: 'replaceAll',
                    }))
                }
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchChecklessReservations(resourceTypeId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/terminal/fetch_checkless_reservations`

        return axios.post(url, {
            authenticity_token: accessToken,
            id: resourceTypeId
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setChecklessReservations(data.payload))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchLocationsForUser(userId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/terminal/fetch_locations`

        return axios.post(url, {
            authenticity_token: accessToken,
            id: userId
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setLocations(data.payload))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchMenuContent(menu=null, options={}) {
    return async (dispatch, getState) => {
        options = {
            loadAll: !!menu?.id ? false : true,
            loadDefault: false,
            ...options
        }

        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        let url = `/companies/${companyId}/locations/${locationId}/pos/menus`

        if (!options.loadAll) {
            url = `${url}/${menu.id}`
        }

        return axios(url).then(({ data }) => {
            if (data.success && data?.payload) {
                switch (true) {
                    case options.loadDefault :
                        dispatch(resetMenuBreadcrumbs())
                        dispatch(setAllMenus([]))
                        dispatch(setMenuContent(data.payload.default))
                        dispatch(addBreadcrumb(data.payload.default))
                        break

                    case options.loadAll :
                        dispatch(setAllMenus(data.payload.menus))
                        dispatch(resetMenuContent())
                        break

                    default : // load specific menu
                        dispatch(setAllMenus([]))
                        dispatch(setMenuContent(data.payload.menu))
                }

                return
            }

            dispatch(addAlert({ type: 'error', text: data.message }))
        })
    }
}

export function fetchMenuItemContent(itemId=null) {
    return async (dispatch, getState) => {
        if (!itemId) { return }

        const companyId            = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId           = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const breadcrumbsToPrepend = getState().point_of_sale.breadcrumbsToPrepend

        const url = `/companies/${companyId}/locations/${locationId}/pos/items/${itemId}`

        return axios(url).then(({ data }) => {
            if (data.success && data?.payload) {
                // prepend any breadcrumbs that were set before we were asked to
                // load the menu item, then immediately clear them out after use
                breadcrumbsToPrepend.map((bc) => dispatch(addBreadcrumb(bc)))
                dispatch(setBreadcrumbsToPrepend([]))

                // keep track of all menus ever loaded to this point
                dispatch(addBreadcrumb({
                    id: data.payload.id,
                    name: data.payload.name,
                    type: data.payload.type,
                    button_color: data.payload.button_color,
                }))

                // set menu item content
                dispatch(setMenuItem(data.payload))

                // set default item config if one is not already set (due to editing an item)
                if (Object.values(getState().point_of_sale.menus.itemConfig).length === 0) {
                    dispatch(setItemDefaultConfig(data.payload))
                }
                return
            }

            dispatch(addAlert({ type: 'error', text: data.message }))
            if (console && !!data?.error) { console.error(data.error) }
        })
    }
}

export function fetchModifierGroups() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/modifier-groups`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setModifierGroups(data.payload.modifier_groups))
                return data.payload.modifier_groups
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchFulfillmentProviders({ unlessPopulated=false }) {
    return async (dispatch, getState) => {
        if (unlessPopulated && Object.values(getState().point_of_sale.fulfillmentProviders).length > 0) { return }

        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/printer-locations`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setFulfillmentProviders(data.payload))
                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchResourceTypes() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/resource-types`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setResourceTypes(data.payload))
                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchChitTabs(chitId) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/pos/chits/${chitId}/fetch_tabs`

        return axios.get(url, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (data.success) {
                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchUsers(company_id=null, location_id=null) {
    return async (dispatch, getState) => {
        const companyId  = company_id  || getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = location_id || getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId ) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to fetch users!' }))
        }

        const url = `/companies/${companyId}/locations/${locationId}/fetch_users`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setUsers(data.payload))
                return data.payload
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchCompReasons({ company_id=null, location_id=null, unlessPopulated=false }) {
    return async (dispatch, getState) => {
        if (unlessPopulated && Object.values(getState().point_of_sale.compReasons).length > 0) { return }

        const companyId  = company_id  || getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = location_id || getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        const url = `/companies/${companyId}/locations/${locationId}/fetch_comp_reasons`

        return axios(url).then(({ data }) => {
            if (data.success) {
                dispatch(setCompReasons(data.payload))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function fetchComps({ unlessPopulated=false }) {
    return async (dispatch, getState) => {
        if (!/^(super|company_admin|manager)$/i.test(getState().session.currentUser.role)) { return }
        if (unlessPopulated && Object.values(getState().point_of_sale.comps).length > 0) { return }

        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        const url = `/companies/${companyId}/locations/${locationId}/fetch_comps`

        return axios.post(url, {
            authenticity_token: accessToken,
            user_id: userId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setComps(data.payload))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function moveChitItemBetweenTabs(fromTabId, toTabId) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const chit       = selectCurrentChit(getState())
        const itemUuid   = getState().point_of_sale.modals.manageChitItem.item.item.uuid

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/chits/${chit.id}/move_tab_item`, {
            authenticity_token: accessToken,
            item_uuid: itemUuid,
            from_id: fromTabId,
            to_id: toTabId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(updateChit(data.payload.chit))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function addMembershipToTab(tabId, membershipId) {
    return async (_, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/tabs/${tabId}/add_membership`, {
            authenticity_token: accessToken,
            membership_id: membershipId
        }).then((data) => {
            return data
        })
    }
}

export function printReceipt(options={}) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const settings   = selectDeviceSettings(getState())
        const check      = selectCurrentCheck(getState())

        options = { ...{
            tab: null,
            changeDueCents: null,
            shouldOpenCashDrawerIfAvailable: false,
            asSeparateJobs: false,
            includeTipLine: false,
            includeSignatureLine: false,
            displayPopModal: true,
        }, ...options }

        if (!companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (!settings?.printerId) {
            dispatch(addAlert({ type: 'error', text: 'Printer preference is unknown!' }))
            return false
        }

        // Fetch the printer via the API instead of from Redux
        //
        // NOTE: This also acts as a last minute check for the status
        // of the printer. If the app loaded with the printer offline
        // and then it came back online afterwards, this will fetch its
        // new status and allow a print job to be created successfully
        const printer = await dispatch(fetchPrinter(settings.printerId))

        if (!printer?.id) {
            dispatch(addAlert({ type: 'error', text: 'Unable to find printer!' }))
            return false
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/print_receipt`, {
            authenticity_token: accessToken,
            tab_id: options?.tab?.id,
            printer_id: printer.id,
            as_separate_jobs: options.asSeparateJobs,
            include_tip_line: options.includeTipLine,
            include_signature_line: options.includeSignatureLine,
            open_cash_drawer: printer.has_cash_drawer && options.shouldOpenCashDrawerIfAvailable,
            number_of_copies: options.numberOfCopies,
            change_due_cents: options.changeDueCents,
        }).then(({ data }) => {
            switch(true) {
                // complete failure
                case data.success === false :
                    dispatch(addAlert({
                        type: 'error',
                        text: data.message,
                    }))
                    dispatch(addAlert({
                        type: 'error',
                        text: Array.isArray(data.errors) ? data.errors.join(', ') : data.errors,
                    }))
                    return data

                // complete success
                case data.with_errors === false :
                    if (options.displayPopModal) {
                        dispatch(configureModal({
                            modal: 'popModal',
                            config: { text: data.message, icon: 'fa-print bg-green' }
                        }))
                        dispatch(openModal('popModal'))
                    }
                    return data

                // partial success
                case data.with_errors === true :
                    return data
            }
        })
    }
}

export function printTransactionReceipt(transactionId=null, tabId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const settings   = selectDeviceSettings(getState())
        const check      = selectCurrentCheck(getState())

        if (!transactionId) {
            dispatch(addAlert({ type: 'error', text: 'Unknown transaction ID!' }))
            return false
        }

        if (!companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (!settings?.printerId) {
            dispatch(addAlert({ type: 'error', text: 'Printer preference is unknown!' }))
            return false
        }

        // Fetch the printer via the API instead of from Redux
        //
        // NOTE: This also acts as a last minute check for the status
        // of the printer. If the app loaded with the printer offline
        // and then it came back online afterwards, this will fetch its
        // new status and allow a print job to be created successfully
        const printer = await dispatch(fetchPrinter(settings.printerId))

        if (!printer?.id) {
            dispatch(addAlert({ type: 'error', text: 'Unable to find printer!' }))
            return false
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/payments/${transactionId}/print_receipt`, {
            authenticity_token: accessToken,
            printer_id: printer.id,
            tab_id: tabId,
            include_tip_line: false,
            include_signature_line: false,
        }).then(({ data }) => {
            dispatch(configureModal({
                modal: 'popModal',
                config: {
                    isOpen: true,
                    text: data.message,
                    icon: data.success ? 'fa-print bg-green' : 'fa-xmark bg-danger',
                }
            }))

            return data
        })
    }
}

export function openCashDrawer() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id
        const settings   = selectDeviceSettings(getState())

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (!settings?.printerId) {
            dispatch(addAlert({ type: 'error', text: 'Printer preference is unknown!' }))
            return false
        }

        const printer = await dispatch(fetchPrinter(settings.printerId))

        if (!printer?.id) {
            dispatch(addAlert({ type: 'error', text: 'Unable to find printer!' }))
            return false
        }

        if (!printer.is_online || printer.has_error) {
            dispatch(addAlert({
                type: 'error',
                text: `Unable to open! (E1)<br />${printer.name}: ${printer?.client_status_text || 'Printer is offline!'}`
            }))
            return false
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/printers/${printer.id}/open_cash_drawer`

        return axios.post(url, {
            authenticity_token: accessToken,
            user_id: userId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))

            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function processChitFulfillment(chit) {
    return async (dispatch, getState) => {
        const companyId         = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId        = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const deviceFingerPrint = selectDeviceFingerPrint(getState())

        const url = `/companies/${companyId}/locations/${locationId}/pos/chits/${chit.id}/process_fulfillment`

        dispatch(setItemEditMode(false))

        return axios.post(url, {
            authenticity_token: accessToken,
            server_id: getState().session.currentUser.id,
            device_fingerprint: deviceFingerPrint,
        }).then(({ data }) => {
            switch(true) {
                // complete failure
                case data.success === false :
                    dispatch(configureModal({
                        modal: 'popModal',
                        config: { text: data.message, size: 'modal-md', icon: 'fa-xmark bg-danger', delay: 2500 }
                    }))
                    dispatch(openModal('popModal'))

                    if (!!data?.errors) {
                        dispatch(addAlert({
                            type: 'error',
                            text: Array.isArray(data.errors) ? data.errors.join(', ') : data.errors,
                        }))
                    }
                    return data

                // complete success
                case data?.with_errors === false :
                    dispatch(configureModal({
                        modal: 'popModal',
                        config: { text: data.message, icon: 'fa-check bg-green' }
                    }))
                    dispatch(openModal('popModal'))
                    return data

                // partial success
                case data?.with_errors === true :
                    return data
            }
        })
    }
}

export function removeItemFromChitTab() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const chit       = selectCurrentChit(getState())
        const tab        = getState().point_of_sale.modals.manageChitItem.tab
        const itemUuid   = getState().point_of_sale.modals.manageChitItem.item.item.uuid

        const url = `/companies/${companyId}/locations/${locationId}/pos/chits/${chit.id}/remove_tab_item`

        return axios.patch(url, {
            authenticity_token: accessToken,
            tab_id: tab.id,
            item_uuid: itemUuid,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(updateChit(data.payload))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function moveCheckItemsBetweenTabs(toTabId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())

        const manageCheckItemModal = getState().point_of_sale.modals.manageCheckItem
        const selectTabModal       = getState().point_of_sale.modals.selectTab

        const item_uuids = manageCheckItemModal.item ? [manageCheckItemModal.item.item.uuid] : selectTabModal.uuids
        const from_id    = manageCheckItemModal.tab?.id || selectTabModal.fromTabId
        const to_id      = toTabId || selectTabModal.toTabId

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/move_tab_items`, {
            authenticity_token: accessToken,
            item_uuids: item_uuids,
            from_id: from_id,
            to_id: to_id,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to move item!' }))
        })
    }
}

export function removeItemFromCheckTab(tabId, itemUuid) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/remove_tab_item`

        return axios.patch(url, {
            authenticity_token: accessToken,
            tab_id: tabId,
            item_uuid: itemUuid,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        }).catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to remove item!' }))
        })
    }
}

export function splitReservationPayment(checkId=null, payload=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId || !payload) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/split_reservation_payment`

        return axios.post(url, {
            authenticity_token: accessToken,
            payload,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
            return data
        }).catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to split payment!' }))
        })
    }
}

export const switchToLocation = (id=null) => {
    return (dispatch) => {
        if (!id) { return false }

        dispatch(updateIsSessionLocked(true, true, () => {
            window.localStorage.setItem(SELECTED_LOCATION, id)
            window.location.href = window.location.pathname.replace(
                /^(\D*)(\d+)(\D*)(\d+)(\D*)/,
                `$1$2$3${id}$5`
            )
        }))
    }
}

export function toggleItemIsAvailable(itemId) {
    return async (dispatch, getState) => {
        const companyId         = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId        = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const currentCategories = getState().point_of_sale.categories

        const url = `/companies/${companyId}/locations/${locationId}/pos/items/${itemId}/toggle_is_available`

        return axios.patch(url, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (data.success) {
                const updatedCategories = currentCategories.map(category => {
                    const updatedItems = category.items.map(item => {
                        if (item.id === itemId) {
                            return { ...item, is_available: !item.is_available }
                        } else {
                            return item
                        }
                    })

                    return { ...category, items: updatedItems }
                })

                dispatch(setCategories(updatedCategories))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function toggleModifierIsAvailable(modifierId) {
    return async (dispatch, getState) => {
        const companyId             = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId            = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const currentModifierGroups = getState().point_of_sale.modifierGroups

        const url = `/companies/${companyId}/locations/${locationId}/pos/modifiers/${modifierId}/toggle_is_available`

        return axios.patch(url, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (data.success) {
                const updatedModifierGroups = currentModifierGroups.map(modifierGroup => {
                    const updatedModifiers = modifierGroup.modifiers.map(modifier => {
                        if (modifier.id === modifierId) {
                            return { ...modifier, is_available: !modifier.is_available }
                        } else {
                            return modifier
                        }
                    })

                    return { ...modifierGroup, modifiers: updatedModifiers }
                })

                dispatch(setModifierGroups(updatedModifierGroups))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function updateTokenizedCardOnTab(tabId=null, action=null, existingProfile=null) {
    return async (dispatch, getState) => {
        const companyId        = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId       = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check            = selectCurrentCheck(getState())
        const terminalResponse = getState().adyen.terminalResponse

        if (!action) {
            dispatch(addAlert({ type: 'error', text: 'You must specify an action!' }))
            return
        }

        if (!check) {
            dispatch(addAlert({ type: 'error', text: "Could not update the card on an unknown check's tab!" }))
            return
        }

        if (!tabId) {
            dispatch(addAlert({ type: 'error', text: 'Could not update the card on an unknown tab!' }))
            return
        }

        const payload = {
            authenticity_token: accessToken,
        }

        if (/^(add|add-existing|create)$/i.test(action)) {
            if (/^(add|create)$/i.test(action)) {
                if (!terminalResponse) {
                    dispatch(addAlert({ type: 'error', text: 'No response from the terminal!' }))
                    return
                } else {
                    payload['terminal_response'] = terminalResponse
                    payload['card_action']       = 'create'
                }
            }

            if (/^(add-existing)$/i.test(action)) {
                if (!existingProfile) {
                    dispatch(addAlert({ type: 'error', text: 'A card profile was not passed in!' }))
                    return
                } else {
                    payload['terminal_response'] = existingProfile
                    payload['card_action']       = 'create'
                }
            }
        }

        if (/^remove|delete|destroy$/i.test(action)) {
            payload['card_action'] = 'destroy'
        }

        const url = `/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/tabs/${tabId}/update-tokenized-card`

        return axios.patch(url, payload).then(({ data }) => {
            if (data.success) {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { isOpen: true, text: data.message, icon: 'fa-floppy-disk bg-green' }
                }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            dispatch(clearAdyenState())
            return data
        })
    }
}

export function updateItemOnChitTab({ tabId, itemUuid, itemConfig }) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const chit       = selectCurrentChit(getState())

        if (!companyId || !locationId || !chit) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/chits/${chit.id}/update_tab_item`, {
            authenticity_token: accessToken,
            tab_id: tabId,
            item_uuid: itemUuid,
            payload: {
                notes: itemConfig.notes,
                config: itemConfig.modifiers,
            },
        }).then(({ data }) => {
            if (data.success) {
                dispatch(updateChit(data.payload))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function updateItemOnCheckTab({ tabId, item, itemUuid, itemConfig }) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())

        if (!companyId || !locationId || !check) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (debug && console) {
            console.log(item)
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/update_tab_item`, {
            authenticity_token: accessToken,
            tab_id: tabId,
            item_uuid: itemUuid,
            payload: {
                notes: itemConfig.notes,
                config: itemConfig.modifiers,
            },
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function renameTab() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())
        const tabId      = getState().point_of_sale.modals.renameTab.tab.id
        const newName    = getState().point_of_sale.modals.renameTab.newName

        if (!companyId || !locationId || !check) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/rename_tab`, {
            authenticity_token: accessToken,
            tab_id: tabId,
            payload: {
                name: newName,
            },
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function addCompToTabItem(comp, tabId) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())
        const itemUuid   = getState().point_of_sale.modals.manageCheckItem.item.item.uuid

        if (!companyId || !locationId || !check) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/add_comp`, {
            authenticity_token: accessToken,
            item_uuid: itemUuid,
            comp: comp,
            user_id: getState().session.currentUser.id,
            tab_id: tabId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setCurrentCheck(data.payload.check))
                dispatch(setComps([data.payload.comp, ...getState().point_of_sale.comps]))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function discountItem(discount) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())
        const tabId      = getState().point_of_sale.modals.manageCheckItem.tab.id
        const itemUuid   = getState().point_of_sale.modals.manageCheckItem.item.item.uuid

        if (!companyId || !locationId || !check) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/discount_tab_item`, {
            authenticity_token: accessToken,
            tab_id: tabId,
            item_uuid: itemUuid,
            discount: discount,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(configureModal({
                    modal: 'manageCheckItem',
                    config: { tab: data.tab, item: data.item }
                }))

                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function tabCheckout({ totalReceivedAmount, tipAmount, checkNumber, giftCardNumber, zipCode, preAuthorizedPaymentId }) {
    return async (dispatch, getState) => {
        const companyId           = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId          = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check               = selectCurrentCheck(getState())
        const tab                 = getState().point_of_sale.checkout.tab
        const currentUser         = getState().session.currentUser
        const paymentType         = getState().point_of_sale.checkout.paymentMethod
        const adyenPayment        = getState().adyen.adyenPayment
        const adyenTerminal       = getState().adyen.selectedTerminal
        const creditToken         = getState().adyen.creditToken
        const creditMethod        = getState().point_of_sale.checkout.creditMethod
        const shouldOverProvision = getState().point_of_sale.checkout.shouldOverProvision

        window.axiosTransactionSource = axios.CancelToken.source()

        window.sessionStorage.setItem(PREVENT_LOADER, true)
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))

        if (!!adyenTerminal) {
            dispatch(setTerminalProcessing(true))
        }

        return axios.request({
            url: `/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/tab_checkout`,
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                authenticity_token: accessToken,
                tab_id: tab.id,
                payment_type: paymentType,
                over_provision: shouldOverProvision,
                total_received_amount: totalReceivedAmount,
                tip_amount: tipAmount,
                check_number: checkNumber,
                gift_card_number: giftCardNumber,
                pre_authorized_payment_id: preAuthorizedPaymentId,
                adyen_payment: adyenPayment,
                adyen_terminal: adyenTerminal,
                adyen_sale_id: window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID),
                adyen_service_id: window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID),
                credit_token: creditToken,
                credit_method: creditMethod,
                credit_zip: zipCode,
                user_id: currentUser.id,
                change_due_cents: paymentType === 'cash' ? getState().point_of_sale.checkout.changeDue * 100 : null,
            },
            headers: {
                timeout: adyenTerminal ? 90 : 20 // 0 indicates no timeout
            }
        }).then(({ data }) => {
            if (data.success) {
                dispatch(unlockTabPayments(tab))
            } else {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: {
                        isOpen: true,
                        text: data.message,
                        icon: 'fa-xmark bg-danger',
                        size: 'lg'
                    }
                }))

                if (data.is_payment_locked) {
                    dispatch(setCurrentComponent('PrintPay'))
                }
            }

            return data
        }).catch((e) => {
            if (axios.isCancel(e)) {
                if (console) { console.log(e.message.toUpperCase()) }
                dispatch(configureModal({
                    modal: 'popModal',
                    config: {
                        isOpen: true,
                        text: 'TRANSACTION<br />CANCELLED',
                        icon: 'fa-xmark bg-danger',
                        size: 'sm'
                    }
                }))
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'TRANSACTION TIMED OUT' }))

                    if (!!adyenTerminal) {
                        dispatch(cancelTerminalTransaction())
                    }
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource

            if (!!adyenTerminal) {
                dispatch(setTerminalProcessing(false))
            }
        })
    }
}

export function fullCheckout({ totalReceivedAmount, tipAmount, checkNumber, giftCardNumber, zipCode, preAuthorizedPaymentId }) {
    return async (dispatch, getState) => {
        const companyId           = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId          = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check               = selectCurrentCheck(getState())
        const currentUser         = getState().session.currentUser
        const paymentType         = getState().point_of_sale.checkout.paymentMethod
        const adyenPayment        = getState().adyen.adyenPayment
        const adyenTerminal       = getState().adyen.selectedTerminal
        const creditToken         = getState().adyen.creditToken
        const creditMethod        = getState().point_of_sale.checkout.creditMethod
        const shouldOverProvision = getState().point_of_sale.checkout.shouldOverProvision

        window.axiosTransactionSource = axios.CancelToken.source()

        window.sessionStorage.setItem(PREVENT_LOADER, true)
        window.sessionStorage.setItem(ADYEN_TERMINAL_SALE_ID, SecureHash(10))
        window.sessionStorage.setItem(ADYEN_TERMINAL_SERVICE_ID, SecureHash(5))

        if (!!adyenTerminal) {
            dispatch(setTerminalProcessing(true))
        }

        return axios.request({
            url: `/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/full_checkout`,
            cancelToken: window.axiosTransactionSource.token,
            method: 'POST',
            data: {
                authenticity_token: accessToken,
                payment_type: paymentType,
                over_provision: shouldOverProvision,
                total_received_amount: totalReceivedAmount,
                tip_amount: tipAmount,
                check_number: checkNumber,
                gift_card_number: giftCardNumber,
                pre_authorized_payment_id: preAuthorizedPaymentId,
                adyen_payment: adyenPayment,
                adyen_terminal: adyenTerminal,
                adyen_sale_id: window.sessionStorage.getItem(ADYEN_TERMINAL_SALE_ID),
                adyen_service_id: window.sessionStorage.getItem(ADYEN_TERMINAL_SERVICE_ID),
                credit_method: creditMethod,
                creditToken: creditToken,
                credit_zip: zipCode,
                user_id: currentUser.id,
                change_due_cents: paymentType === 'cash' ? getState().point_of_sale.checkout.changeDue * 100 : null,
            },
            headers: {
                timeout: adyenTerminal ? 90 : 20 // 0 indicates no timeout
            }
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: {
                        isOpen: true,
                        text: data.message,
                        icon: 'fa-xmark bg-danger',
                        size: 'lg'
                    }
                }))

                if (data.is_payment_locked) {
                    dispatch(setCurrentComponent('PrintPay'))
                }
            }

            return data
        }).catch((e) => {
            if (axios.isCancel(e)) {
                if (console) { console.log(e.message.toUpperCase()) }
                dispatch(configureModal({
                    modal: 'popModal',
                    config: {
                        isOpen: true,
                        text: 'TRANSACTION<br />CANCELLED',
                        icon: 'fa-xmark bg-danger',
                        size: 'sm'
                    }
                }))
            } else {
                if (console) { console.warn(e) }

                if (e.response.status === 504) {
                    dispatch(addAlert({ type: 'error', text: 'TRANSACTION TIMED OUT' }))

                    if (!!adyenTerminal) {
                        dispatch(cancelTerminalTransaction())
                    }
                } else {
                    dispatch(addAlert({ type: 'error', text: e?.response?.data?.message || 'TRANSACTION FAILED!' }))
                }
            }
        }).finally(() => {
            window.sessionStorage.removeItem(PREVENT_LOADER)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SALE_ID)
            window.sessionStorage.removeItem(ADYEN_TERMINAL_SERVICE_ID)
            delete window.axiosTransactionSource

            if (!!adyenTerminal) {
                dispatch(setTerminalProcessing(false))
            }
        })
    }
}

export function checkGiftCardBalance(card_number) {
    return async (_dispatch, getState) => {
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        return axios.post(`/gift-cards/${card_number}/balance`, {
            authenticity_token: accessToken,
            location_id: locationId,
            card_number,
        }).then(response => {
            return response.data
        }).catch((error) => {
            if (debug && console) { console.error(error) }
        })
    }
}

export function sendReceipt(email) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())
        const tab        = getState().point_of_sale.modals.emailReceipt.tab
        const callbacks  = getState().point_of_sale.modals.emailReceipt.callbacks

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/send_receipt`, {
            authenticity_token: accessToken,
            tab_id: tab?.id,
            email: email,
            items: !!tab ? JSON.stringify(sortByAddedDateTime(tab.items), 'asc') : null,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: data.message, icon: 'fa-check bg-green' }
                }))
                dispatch(openModal('popModal'))
                if (!!callbacks.afterSuccess) { callbacks.afterSuccess() }
                return data
            } else {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: data.message, icon: 'fa-xmark bg-danger' }
                }))
                dispatch(openModal('popModal'))
                if (!!callbacks.afterFailure) { callbacks.afterFailure() }
            }
        })
    }
}

export function disableIndividualTabPayments() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !check || !userId) {
            if (console) { console.warning('Unable to disable individual tab payments')}
            return false
            // return dispatch(addAlert({ type: 'error', text: 'Unable to disable individual tab payments' }))
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/disable_individual_tab_payments`, {
            authenticity_token: accessToken,
            terminal_user_id: userId,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
            return data
        })
    }
}

export function enableIndividualTabPayments() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())

        if (!companyId || !locationId || !check) {
            if (console) { console.warning('Unable to enable individual tab payments')}
            return false
            //return dispatch(addAlert({ type: 'error', text: 'Unable to enable individual tab payments' }))
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/enable_individual_tab_payments`, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
            return data
        })
    }
}

export function lockTabPayments(tab) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !check || !tab) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to lock tab payments' }))
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/tabs/${tab.id}/lock_payment`, {
            authenticity_token: accessToken,
            terminal_user_id: userId,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function unlockTabPayments(tab) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const check      = selectCurrentCheck(getState())

        if (!companyId || !locationId || !check || !tab) {
            return dispatch(addAlert({ type: 'error', text: 'Unable to unlock tab payments' }))
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${check.id}/tabs/${tab.id}/unlock_payment`, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function approveComp(comp) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/comps/${comp.id}/approve`, {
            authenticity_token: accessToken,
            manager_id: getState().session.currentUser.id,
        }).then(({ data }) => {
            if (data.success) {
                let compsCopy = [...getState().point_of_sale.comps]
                const compIndex = compsCopy.findIndex(comp => comp.id === data.payload.comp.id)
                compsCopy[compIndex] = data.payload.comp

                dispatch(setComps(compsCopy))
                dispatch(setCurrentCheck(data.payload.check))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function declineComp(comp) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/comps/${comp.id}/decline`, {
            authenticity_token: accessToken,
            manager_id: getState().session.currentUser.id,
        }).then(({ data }) => {
            if (data.success) {
                let compsCopy = [...getState().point_of_sale.comps]
                const compIndex = compsCopy.findIndex(comp => comp.id === data.payload.comp.id)
                compsCopy[compIndex] = data.payload.comp

                dispatch(setComps(compsCopy))
                dispatch(setCurrentCheck(data.payload.check))
                dispatch(addAlert({ type: 'success', text: data.message }))
                return data
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }
        })
    }
}

export function endShift() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/users/${userId}/end_shift`, {
            authenticity_token: accessToken,
            user_id: userId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(addAlert({ type: 'success', text: data.message }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function getShiftReport() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return (
            axios.get(`/companies/${companyId}/locations/${locationId}/pos/users/${userId}/shift_report`)
                 .then((data) => data)
                 .catch((error) => {
                     if (debug && console) { console.error(error) }
                     dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to fetch report!' }))
                 })
        )
    }
}

export function printShiftReport() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id
        const settings   = selectDeviceSettings(getState())

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (!settings?.printerId) {
            dispatch(addAlert({ type: 'error', text: 'Printer preference is unknown!' }))
            return false
        }

        // Fetch the printer via the API instead of from Redux
        //
        // NOTE: This also acts as a last minute check for the status
        // of the printer. If the app loaded with the printer offline
        // and then it came back online afterwards, this will fetch its
        // new status and allow a print job to be created successfully
        const printer = await dispatch(fetchPrinter(settings.printerId))

        if (!printer?.id) {
            dispatch(addAlert({ type: 'error', text: 'Unable to find printer!' }))
            return false
        }

        if (!printer.is_online || printer.has_error) {
            dispatch(addAlert({
                type: 'error',
                text: `${printer.name}: ${printer?.client_status_text || 'Printer is offline!'}`
            }))
            return false
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/users/${userId}/print_shift_report`, {
            authenticity_token: accessToken,
            printer_id: settings.printerId,
            user_id: userId,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: data.message, icon: 'fa-check bg-green', size: 'md' }
                }))
            } else {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: (data?.errors || data?.message || 'Unknown Error!'), icon: 'fa-xmark bg-danger', delay: 750 }
                }))
            }

            dispatch(openModal('popModal'))

            return data
        })
    }
}

export function getCloseDayReport() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/close_day_report`, {
            authenticity_token: accessToken,
            user_id: userId,
        })
        .then((data) => data)
        .catch((error) => {
            if (debug && console) { console.error(error) }
            dispatch(addAlert({ type: 'error', text: error?.response?.data?.message || 'Unable to fetch report!' }))
        })
    }
}

export function printCloseDayReport() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id
        const settings   = selectDeviceSettings(getState())

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (!settings?.printerId) {
            dispatch(addAlert({ type: 'error', text: 'Printer preference is unknown!' }))
            return false
        }

        // Fetch the printer via the API instead of from Redux
        //
        // NOTE: This also acts as a last minute check for the status
        // of the printer. If the app loaded with the printer offline
        // and then it came back online afterwards, this will fetch its
        // new status and allow a print job to be created successfully
        const printer = await dispatch(fetchPrinter(settings.printerId))

        if (!printer?.id) {
            dispatch(addAlert({ type: 'error', text: 'Unable to find printer!' }))
            return false
        }

        if (!printer.is_online || printer.has_error) {
            dispatch(addAlert({
                type: 'error',
                text: `${printer.name}: ${printer?.client_status_text || 'Printer is offline!'}`
            }))
            return false
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/print_close_day_report`, {
            authenticity_token: accessToken,
            printer_id: settings.printerId,
            user_id: userId,
            timeout: 300000,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: data.message, icon: 'fa-check bg-green', size: 'md' }
                }))
            } else {
                dispatch(configureModal({
                    modal: 'popModal',
                    config: { text: (data?.errors || data?.message || 'Unknown Error!'), icon: 'fa-xmark bg-danger', delay: 750 }
                }))
            }

            dispatch(openModal('popModal'))

            return data
        })
    }
}

export function updateOpenCheckPreference() {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const userId     = getState().session.currentUser.id

        if (!companyId || !locationId || !userId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/users/${userId}/update_open_check_preference`, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (data.success) {
                dispatch(setCurrentUser({ show_open_checks_for_everyone: data.payload.user.show_open_checks_for_everyone }))
            } else {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function menuSearch(query=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)
        const modal      = getState().point_of_sale.modals.search

        if (!query || !companyId || !locationId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        let updatedRecentQueries = unique([...modal.recentQueries, query])

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/menu-search`, {
            authenticity_token: accessToken,
            query: query,
        }).then(({ data }) => {
            if (data.success && !!data.payload) {
                dispatch(configureModal({
                    modal: 'search',
                    config: { ...modal,
                        results: data.payload,
                        recentQueries: updatedRecentQueries,
                    }
                }))
            } else {
                dispatch(configureModal({
                    modal: 'search',
                    config: { ...modal,
                        recentQueries: updatedRecentQueries,
                        error: data?.message || 'Unknown error during search'
                    }
                }))
            }

            return data
        }).catch(() => {
            dispatch(configureModal({
                modal: 'search',
                config: { ...modal,
                    recentQueries: updatedRecentQueries,
                    error: data?.message || 'Unknown error during search'
                }
            }))
        })
    }
}

export function bulkCaptureTransactionsForChecks(checkIds=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkIds) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.post(`/companies/${companyId}/locations/${locationId}/pos/checks/bulk-capture`, {
            authenticity_token: accessToken,
            check_ids: checkIds,
        }).then(({ data }) => {
            if (data.success && data?.errors?.length > 0) {
                dispatch(addAlert({ type: 'error', text: 'Not all payment capture requests were successful!' }))
            }

            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function cancelTransaction(checkId=null, transactionId=null, type='default') {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId || !transactionId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/payments/${transactionId}/cancel`, {
            authenticity_token: accessToken,
            type,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function captureTransaction(checkId=null, transactionId=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId || !transactionId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/payments/${transactionId}/capture`, {
            authenticity_token: accessToken,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function updateTransactionCaptureReadiness(checkId=null, transactionId=null, isReady=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId || !transactionId || isReady === null) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/payments/${transactionId}/readiness`, {
            authenticity_token: accessToken,
            readiness: isReady,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export function updateTransactionTipCents(checkId=null, transactionId=null, tipCents=null) {
    return async (dispatch, getState) => {
        const companyId  = getState().point_of_sale.companyId  || window.localStorage.getItem(SELECTED_COMPANY)
        const locationId = getState().point_of_sale.locationId || window.localStorage.getItem(SELECTED_LOCATION)

        if (!companyId || !locationId || !checkId || !transactionId) {
            dispatch(addAlert({ type: 'error', text: 'Not enough info provided' }))
            return false
        }

        if (typeof tipCents !== 'number' || Number(tipCents) < 0) {
            dispatch(addAlert({ type: 'error', text: 'Invalid tip amount' }))
            return false
        }

        return axios.patch(`/companies/${companyId}/locations/${locationId}/pos/checks/${checkId}/payments/${transactionId}/adjust-tip`, {
            authenticity_token: accessToken,
            tip_cents: tipCents,
        }).then(({ data }) => {
            if (!data.success) {
                dispatch(addAlert({ type: 'error', text: data.message }))
            }

            return data
        })
    }
}

export default advancedPointOfSaleSlice.reducer
