import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { selectTimeZone } from '@/features/Locations/locationSlice'

import {
    cancelTransaction,
    captureTransaction,
    updateTransactionCaptureReadiness,
    updateTransactionTipCents,
    printTransactionReceipt,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Icon from '@/components/FontAwesomeIcon'
import Input from '@/components/Form/Input'

import {
    isTransactionRefunded,
    isTransactionCredit,
    isTransactionTerminal,
    isTransactionProcessing,
    isTransactionReadyToCapture,
    isTransactionCapturing,
    isTransactionCaptured,
    isTransactionCaptureRequested,
    isTransactionPendingPreAuth,
    isTransactionAdjustmentPending,
    isTransactionCancellationRequested,
    isTransactionCancelling,
    isTransactionCancelled,
    isTransactionSettled,
} from '@/features/AdvancedPointOfSale/lib/Transactions'

import { useConfirm } from '@/lib/useConfirmHook'
import { numberToCurrency } from '@/lib/Number'

const tipInputStyles = { minWidth: '50px', width: 'auto', maxWidth: '90px' }
const buttonStyles   = { fontSize: '90%' }

export default function Transaction({
    className=null,
    actionDrawerClassName='bg-gray0',
    check=null,
    tab=null,
    data:transaction={}
}) {

    const { confirm }         = useConfirm()
    const dispatch            = useDispatch()
    const timezone            = useSelector(selectTimeZone)
    const errorMessages       = transaction.error_messages
    const isTipPresent        = transaction?.pending_amount_adjustment?.tip_cents > 0 || transaction.tip_cents > 0
    const isRefunded          = isTransactionRefunded(transaction)
    const isCredit            = isTransactionCredit(transaction)
    const isSettled           = isTransactionSettled(transaction)
    const isPending           = isTransactionPendingPreAuth(transaction)
    const isCancelRequested   = isTransactionCancellationRequested(transaction)
    const isCancelling        = isTransactionCancelling(transaction)
    const isCancelled         = isTransactionCancelled(transaction)
    const isReadyToCapture    = isTransactionReadyToCapture(transaction)
    const isCaptureRequested  = isTransactionCaptureRequested(transaction)
    const isCapturing         = isTransactionCapturing(transaction)
    const isCaptured          = isTransactionCaptured(transaction)
    const isAdjustmentPending = isTransactionAdjustmentPending(transaction)

    const [isDrawerOpen, setIsDrawerOpen] = useState(null)
    const [isEditing, setEditing]         = useState(false)
    const [isSaving, setSaving]           = useState(isAdjustmentPending)
    const [isSaved, setSaved]             = useState(false)
    const [tipAmount, setTipAmount]       = useState(isTipPresent ? transaction.tip_cents / 100 : null)
    const [tipCents, setTipCents]         = useState(isTipPresent ? transaction.tip_cents : 0)

    const badge = () => {
        switch(true) {
            case isCredit && isCancelling : return (
                <span className='badge badge-danger w-100' children='CANCELLING' />
            )

            case isCredit && isCancelled : return (
                <span className='badge badge-danger w-100' children='CANCELLED' />
            )

            case isRefunded : return (
                <span className='badge badge-danger w-100' children='REFUNDED' />
            )

            case isPending && isSettled && isCapturing : return (
                <span className='badge badge-warning w-100' children='CAPTURING' />
            )

            case isTransactionTerminal(transaction) && isTransactionProcessing(transaction) : return (
                <span className='badge badge-warning fa-fade w-100' children='PROCESSING' />
            )

            case !isCredit && !isSettled :
            case isCredit && isPending && isSettled && !isCaptured : return (
                <span className='badge badge-warning w-100' children='PENDING' />
            )

            case !isCredit && isSettled :
            case isCredit && isSettled && isCaptured : return (
                <span className='badge badge-success w-100' children='COMPLETE' />
            )

            // THIS SHOULD NEVER RENDER!
            default : return (
                <span className='badge badge-info w-100 fa-fade' children='! UNKNOWN !' />
            )
        }
    }

    const handleCancelAdjustTip = () => {
        setTipAmount(isTipPresent ? transaction.tip_cents / 100 : null)
        setTipCents(isTipPresent ? transaction.tip_cents : 0)
        setEditing(false)
        setSaving(false)
        setSaved(false)
    }

    const handleSaveTip = () => {
        setSaving(true)

        dispatch(updateTransactionTipCents(check.id, transaction.id, tipCents))
        .then((data) => {
            if (data.success) {
                setSaved(true)
            } else {
                setTipAmount(isTipPresent ? transaction.tip_cents / 100 : null)
                setTipCents(isTipPresent ? transaction.tip_cents : 0)
                setSaved(false)
            }
        })
        .catch(() => {
            setTipAmount(isTipPresent ? transaction.tip_cents / 100 : null)
            setTipCents(isTipPresent ? transaction.tip_cents : 0)
            setSaved(false)
        })
        .finally(() => {
            setEditing(false)
            setSaving(false)
        })
    }

    const handleCancelTransaction = async () => {
        if (await confirm(
            '<p class="h5 mb-0 font-weight-bold">Cancel this transaction immediately?</p>' +
            '<p class="h5 mt-2 mb-0 font-weight-light font-italic pt-4">This action <u>cannot</u> be reversed!</p>',
            { size: 'sm', dangerous: true, rawHTML: true })
        ) {
            setIsDrawerOpen(false)
            dispatch(cancelTransaction(check.id, transaction.id))
        }
    }

    const handleCaptureTransaction = async () => {
        if (await confirm(
            '<p class="h5 mb-0 font-weight-bold">Manually capture this payment immediately rather than waiting until the end of your shift?</p>' +
            '<p class="h5 mt-2 mb-0 font-weight-light font-italic pt-4">This action <u>cannot</u> be reversed unless a refund is processed and tips will no longer be editable.</p>',
            { size: 'sm', dangerous: true, rawHTML: true })
        ) {
            setIsDrawerOpen(false)
            dispatch(captureTransaction(check.id, transaction.id))
        }
    }

    const handleSetReadyToCapture = () => {
        dispatch(updateTransactionCaptureReadiness(check.id, transaction.id, !isReadyToCapture))
    }

    const handlePrintReceipt = (transactionId) => {
        dispatch(printTransactionReceipt(transactionId, tab?.id))
    }

    useEffect(() => {
        setIsDrawerOpen(transaction?.error_messages?.length > 0)
    }, [transaction?.error_messages])

    return !!transaction?.id && <>
        <tr
            id={`payment-${transaction.id}`}
            data-psp-reference={transaction.adyen_psp_reference}
            className={[
                isCancelled || isRefunded ? 'text-strikethrough opacity-40' : '',
                className,
            ].join(' ')}
        >
            <td className='py-2 pl-2 pr-3'>
                { badge() }
            </td>
            <td className='py-2 px-2'>
                { moment.tz(transaction.created_at, timezone).format('MM/DD h:mm:ss A') }
            </td>
            <td className='py-2 px-3 text-capitalize'>
                { transaction.transaction_type }

                {
                    !!transaction.card_icon && (
                        <i className={`${transaction.card_icon} fa-lg ml-2`} />
                    )
                }
            </td>
            <td className='py-2 px-2 text-capitalize'>
                { transaction.processed_by }
            </td>
            <td className='py-2 px-3 text-right font-weight-bolder'>
                { numberToCurrency((transaction.total_received_cents - transaction.tip_cents) / 100) }
            </td>
            <td className={`py-2 px-0 text-right ${isCancelled ? 'font-weight-bolder' : 'd-flex align-items-center justify-content-between'}`}>
                {
                    isCancelled ? <>
                        { isTipPresent ? numberToCurrency(transaction.tip_cents / 100) : ' — ' }
                    </> : <>
                        {
                            isEditing ? (
                                <Input
                                    containerClassName='d-flex flex-nowrap'
                                    className='border border-color-gray3 py-1'
                                    name='tip'
                                    type='number'
                                    min='0'
                                    step='.01'
                                    placeholder='0.00'
                                    withWrapper={false}
                                    value={tipAmount}
                                    style={tipInputStyles}
                                    hideLabel
                                    prepend
                                    append
                                    handleChange={(e) => {
                                        setTipAmount(e.target.value)
                                        setTipCents(Number.parseFloat(e.target.value) * 100)
                                    }}
                                >
                                    <div className='input-group-prepend'>
                                        <button
                                            type='button'
                                            children={<Icon packs={['fa-solid']} icon='fa-xmark fa-xl' />}
                                            className='btn btn-secondary text-white p-2'
                                            disabled={isSaving}
                                            onClick={handleCancelAdjustTip}
                                        />
                                    </div>

                                    <div className='input-group-append'>
                                        <button
                                            type='button'
                                            children={<Icon packs={['fa-solid']} icon={`${isSaving ? 'fa-clock' : 'fa-floppy-disk'} fa-xl`} />}
                                            className={`btn btn-${isSaved ? 'success' : 'primary'} py-2 px-3`}
                                            disabled={!tipAmount || isSaving}
                                            onClick={handleSaveTip}
                                        />
                                    </div>
                                </Input>
                            ) : isCredit && <>
                                {
                                    isPending && !isCapturing && !isCancelling && (
                                        <button
                                            children={
                                                isAdjustmentPending
                                                    ? <i className='fas fa-clock fa-xl mx-3' />
                                                    : isTipPresent
                                                        ? 'Edit Tip'
                                                        : 'Add Tip'
                                            }
                                            type='button'
                                            className='btn btn-outline-primary py-2 px-3 mr-3'
                                            style={buttonStyles}
                                            disabled={isAdjustmentPending || isReadyToCapture}
                                            onClick={() => setEditing(true)}
                                        />
                                    )
                                }

                                {
                                    isTipPresent ? (
                                        <span className='badge badge-success pl-2 mt-1'>
                                            { numberToCurrency(Number(transaction?.pending_amount_adjustment?.tip_cents || tipCents || 0) / 100) } TIP
                                        </span>
                                    ) : (
                                        <span className='w-100'> — </span>
                                    )
                                }
                            </>
                        }
                    </>
                }
            </td>
            <td className='py-2 px-3 text-right font-weight-bolder'>
                { numberToCurrency((transaction.payment_amount_cents + Number(transaction?.pending_amount_adjustment?.tip_cents || tipCents || 0)) / 100) }
            </td>
            <td className='py-2 pl-2 pr-3 text-right text-gray6 font-weight-bolder'>
                { isCredit ? numberToCurrency(transaction.maximum_authorized_amount_cents / 100) : '' }
            </td>
            <td className='py-2 px-0 text-right'>
                {
                    !isCancelling && !isCancelled && transaction.is_card_on_record && (
                        <i className='fas fa-paperclip' style={{ position: 'relative', fontSize: '21px', top: '3px' }} />
                    )
                }

                {
                    !isCancelled && isPending && !isCapturing && !isCancelling && !isRefunded && (
                        <button
                            type='button'
                            className={`btn btn-${isReadyToCapture ? 'success' : 'outline-success'} py-2 px-3 ml-3`}
                            style={buttonStyles}
                            disabled={isEditing || isSaving || isAdjustmentPending}
                            onClick={handleSetReadyToCapture}
                        >
                            READY
                            { !isReadyToCapture && <i className='far fa-circle fa-xl ml-2' />}
                            { isReadyToCapture && <i className='fas fa-circle-check fa-xl ml-2' />}
                        </button>
                    )
                }

                {
                    (!isCredit || (isCredit && !isCancelled)) && !isRefunded && (
                        <button
                            children={<Icon packs={['fa-solid']} icon={`fa-${isDrawerOpen ? 'angle-down' : 'angle-up'} fa-xl`} />}
                            type='button'
                            className='btn btn-secondary text-white py-2 px-3 ml-3'
                            style={buttonStyles}
                            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                        />
                    )
                }
            </td>
        </tr>
        {
            !isCancelled && !isRefunded && isDrawerOpen && (
                <tr id={`payment-${transaction.id}--action-drawer`} className={actionDrawerClassName}>
                    <td className='py-3 pl-4 pr-0' colSpan='5'>
                        {
                            errorMessages?.length > 0 && (
                                <div className='w-100 d-flex align-items-center justify-items-start' role='alert'>
                                    <span className={`warning-stack fa-layers fa-2xl mr-2 fa-fade `} style={{ minWidth: '28px' }}>
                                        <i className="fas fa-triangle-exclamation text-danger" />
                                        <i className="fa-inverse fas fa-exclamation text-white" data-fa-transform="shrink-7 down-1" />
                                    </span>

                                    <div>
                                        {
                                            errorMessages.map((error, index) => (
                                                <em
                                                    key={`error_${index}`}
                                                    className={`d-block w-100 text-wrap text-danger ${index === 0 ? '' : 'pt-2'} px-3`}
                                                    children={errorMessages.length > 1 ? `— ${error}` : error}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </td>
                    <td className='py-3 px-3 text-right' colSpan='4'>
                        {
                            isCredit && isPending && <>
                                <button
                                    children='CANCEL TRANSACTION'
                                    type='button'
                                    className='btn btn-outline-danger py-2 px-4 ml-3'
                                    style={buttonStyles}
                                    disabled={
                                        isEditing           ||
                                        isSaving            ||
                                        isCaptureRequested  ||
                                        isCapturing         ||
                                        isCancelRequested   ||
                                        isCancelling        ||
                                        isAdjustmentPending
                                    }
                                    onClick={handleCancelTransaction}
                                />
                                <button
                                    children='CAPTURE NOW'
                                    type='button'
                                    className='btn btn-outline-primary py-2 px-4 ml-3'
                                    style={buttonStyles}
                                    disabled={
                                        isEditing           ||
                                        isSaving            ||
                                        isCaptureRequested  ||
                                        isCapturing         ||
                                        isCancelRequested   ||
                                        isCancelling        ||
                                        isAdjustmentPending
                                    }
                                    onClick={handleCaptureTransaction}
                                />
                            </>
                        }


                        {
                            (!isCredit || (isCredit && !isCancelled)) && (
                                <button
                                    children='PRINT RECEIPT'
                                    type='button'
                                    className='btn btn-primary py-2 px-4 ml-3'
                                    style={buttonStyles}
                                    disabled={
                                        isEditing           ||
                                        isSaving            ||
                                        isAdjustmentPending ||
                                        isCancelRequested   ||
                                        isCancelling        ||
                                        isCapturing
                                    }
                                    onClick={() => handlePrintReceipt(transaction.id)}
                                />
                            )
                        }
                    </td>
                </tr>
            )
        }
    </>
}
