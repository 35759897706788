import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { parameterize } from 'inflected'

import {
    fetchCheck,
    fetchCheckPayments,
    configureModal,
    openModal,
    selectCurrentCheck,
    setCurrentComponent,
    disableIndividualTabPayments,
    closeCheck,
    enableIndividualTabPayments,
    resetCurrentCheck,
    loadDefaultComponent,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import Breadcrumbs from '@/features/AdvancedPointOfSale/components/Breadcrumbs'
import ButtonGroup from '@/features/AdvancedPointOfSale/components/buttons/ButtonGroup'
import StickyElement from '@/features/AdvancedPointOfSale/components/StickyElement'
import TabTableRow from '@/features/AdvancedPointOfSale/components/print-pay/TabTableRow'
import scrollHinting from '@/lib/ScrollHinting'
import { numberToCurrency } from '@/lib/Number'
import { useConfirm } from '@/lib/useConfirmHook'
import { useManagerOverride } from '@/lib/useManagerOverrideHook'

import {
    addBookingTabToGeneralTab,
    checkNameForBreadcrumbs,
    isPaid,
    isPartiallyPaid,
    isUnpaid
} from '@/features/AdvancedPointOfSale/lib/Checks'

export default function PrintPay({
    self = 'PrintPay',
    transactionsAllowed=false,
    onChange = () => {}
}) {

    const { confirm }   = useConfirm()
    const { authorize } = useManagerOverride()
    const dispatch      = useDispatch()
    const check         = useSelector(selectCurrentCheck)

    const allTabs         = useMemo(() => addBookingTabToGeneralTab(check.tabs), [check.tabs])
    const pendingComps    = useMemo(() => check.comps.filter(comp => comp.status === 'pending'), [check.comps])
    const hasMultipleTabs = useMemo(() => allTabs.length > 1, [allTabs])
    const hasLockedTabs   = useMemo(() => allTabs.filter(tab => !!tab.payment_locked_by_id && tab.payment_status == 'unpaid').length > 0, [allTabs])
    const checkHasItems   = useMemo(() => allTabs.filter(tab => tab.items.length > 0).length > 0, [allTabs])
    const hasTransactions = (check.total_paid_cents || 0) > 0

    const handleOpenTransactionsModal = () => {
        dispatch(fetchCheckPayments(check.id)).then(() => {
            dispatch(openModal('transactions'))
        })
    }

    const handleViewReceipt = () => {
        dispatch(configureModal({
            modal: 'receipt',
            config: {
                tabs: allTabs,
                forFullCheck: true,
                footer: 'complex'
            }
        }))

        dispatch(openModal('receipt'))
    }

    const handlePrintFullCheck = () => {
        dispatch(configureModal({
            modal: 'printOption',
            config: {
                title: 'Print Full Check',
                data: {
                    includeTipLine: true,
                    includeSignatureLine: true,
                }
            }
        }))
        dispatch(openModal('printOption'))
    }

    const handlePrintAllTabsSeparately = () => {
        dispatch(configureModal({
            modal: 'printOption',
            config: {
                title: 'Print All Tabs Separately',
                data: {
                    asSeparateJobs: true,
                    includeTipLine: true,
                    includeSignatureLine: true,
                }
            }
        }))
        dispatch(openModal('printOption'))
    }

    const handleConfirmCompPending = async () => {
        if (await confirm(`Please resolve the pending ${pendingComps.length > 1 ? 'comps' : 'comp'}<br />before processing a payment`, {
            header_text: `${pendingComps.length > 1 ? 'Multiple Item Comps Are' : 'An Item Comp Is'} Pending!`,
            confirm_only: true,
            confirm_text: 'OK',
            size: 'sm'
        })) { return }
    }

    const handlePayFullCheck = () => {
        if (pendingComps.length > 0) {
            handleConfirmCompPending()
        } else {
            dispatch(disableIndividualTabPayments()).then((data) => {
                if (data.success) {
                    dispatch(fetchCheck(check.id)).then((_check) => {
                        if (!!_check) {
                            dispatch(setCurrentComponent('Checkout'))
                        }
                    })
                }
            })
        }
    }

    const handleCloseCheck = async () => {
        if (!await confirm('This action cannot be undone.', { header_text: 'Are you sure you want to close this check?', dangerous: true })) { return }

        dispatch(closeCheck()).then((data) => {
            if (data.success) {
                dispatch(loadDefaultComponent())
                dispatch(resetCurrentCheck())
            }
        })
    }

    const handleUnlockIndividualTabPayments = async () => {
        if (await authorize()) {
            dispatch(enableIndividualTabPayments())
        }
    }

    useEffect(() => {
        dispatch(fetchCheck(check.id))
    }, [])

    useEffect(() => {
        onChange(
            <Breadcrumbs
                id='advanced-pos--terminal-navigation--breadcrumbs'
                data={[
                    { id: check.id, name: checkNameForBreadcrumbs(check, (check.booking?.resource_name || '')), type: 'check', component: 'DefaultMenu' },
                    { id: null, name: 'Manage Check', type: null, component: 'ManageCheck' },
                    { id: null, name: 'Print / Pay', type: null },
                ]}
                onClick={(bc) => dispatch(setCurrentComponent(bc.component))}
            />
        )

        if (check.has_unallocated_booking_credits) {
                dispatch(configureModal({
                        modal: 'popModal',
                        config: {
                                text: 'This Check Has Reservation Overpayment!',
                                icon: 'fa-xmark bg-danger',
                                size: 'md',
                                delay: 5000
                        }
                }))
                dispatch(openModal('popModal'))
        }

        scrollHinting.search()
    }, [check])

    return (
        <div id={`advanced-pos-terminal--${parameterize(self)}`} className='list-container py-3 px-4'>
            <StickyElement
                className='border-bottom border-bottom-gray4 d-flex justify-content-between align-items-end pb-2'
                stickyClasses='m-n4 pt-2 pb-3 px-4 bg-gray0'
                offset='-80'
            >
                <span className='d-flex flex-row flex-wrap'>
                    <span className='w-100'>
                        {
                            !!check.booking?.resource_name && (
                                <span className='h2 mb-0 pr-3'>{check.booking.resource_name}</span>
                            )
                        }

                        <span className={`${!!check.booking?.resource_name ? 'h5 font-weight-bold' : 'h3 font-weight-ligher'} font-source-sans pr-3`}>
                            {check.name}
                        </span>

                        <span
                            className='text-uppercase font-weight-lighter text-gray4'
                            children={check?.booking?.reservation_number || check.check_number}
                            style={{ fontSize: '14px' }}
                        />
                    </span>
                </span>

                <ButtonGroup className='theme--all-solid'>
                    {
                        hasTransactions && (
                            <button
                                type='button'
                                children='View Transactions'
                                onClick={handleOpenTransactionsModal}
                            />
                        )
                    }

                    {
                        hasMultipleTabs && (
                            <button
                                type='button'
                                children='Print All Tabs Separately'
                                onClick={handlePrintAllTabsSeparately}
                            />
                        )
                    }
                </ButtonGroup>
            </StickyElement>

            <div className='scrollhint h-100' style={{ maxHeight: 'calc(100vh - 164px)' }}>
                <div className='scrollhint--inner table-responsive'>
                    <table className='table print-pay-table'>
                        <colgroup>
                            <col width='0px' />
                            <col width='auto' />
                            <col width='0px' />
                            <col width='0px' />
                            <col width='0px' />
                            <col width='0px' />
                            <col width='0px' />
                            <col width='160px' />
                            <col width='180px' />
                            <col width='180px' />
                        </colgroup>
                        <thead className='border-bottom-thick'>
                            <tr>
                                <th />
                                <th colSpan='2' />
                                <th className='text-right px-2'><small>Comps &<br />Discounts</small></th>
                                <th className='text-right px-2'><small>Total<br />Due</small></th>
                                <th className='text-right px-2'><small>Balance<br />Paid</small></th>
                                <th className='text-right px-2'><small>Remaining<br />Balance</small></th>
                                <th className='text-center pl-2 pr-0'>
                                    {
                                        check.pending_amount_paid_cents > 0 && (
                                            <span className='badge badge-success fa-fade text-center w-100 mb-1'>
                                                { numberToCurrency(check.pending_amount_paid_cents / 100) }&nbsp; PENDING
                                            </span>
                                        )
                                    }
                                </th>
                                <th />
                                <th className='text-right px-2'>
                                    {
                                        (isPaid(check) || check.fully_paid || check.grand_total_cents === 0) && (
                                            <button
                                                type='button'
                                                className='btn btn-primary w-100 px-1'
                                                children='Close Check'
                                                onClick={handleCloseCheck}
                                            />
                                        )
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-left font-weight-bolder border-bottom-thick pl-2' />

                                <td className='text-left font-weight-bolder border-bottom-thick pl-2' colSpan='2'>
                                    <span>ALL TABS BALANCE</span>
                                </td>

                                <td className='text-right text-green border-bottom-thick'>
                                    { numberToCurrency(-1 * (check.discount_cents / 100)) }
                                </td>

                                <td className='text-right border-bottom-thick'>
                                    { numberToCurrency(check.grand_total_cents / 100) }
                                </td>

                                <td className='text-right border-bottom-thick'>
                                    { numberToCurrency((check.total_paid_cents - check.refund_total) / 100) }
                                </td>

                                <td className='text-right border-bottom-thick'>
                                    { numberToCurrency(check.balance_cents / 100) }
                                </td>

                                <td className='text-right border-bottom-thick px-0'>
                                    {
                                        hasMultipleTabs && (
                                            <button
                                                children='View Receipt'
                                                type='button'
                                                className='btn btn-link text-blue text-uppercase font-weight-bolder'
                                                disabled={!checkHasItems}
                                                onClick={handleViewReceipt}
                                            />
                                        )
                                    }
                                </td>

                                <td className='text-right border-bottom-thick'>
                                    {
                                        hasMultipleTabs && (
                                            <button
                                                children='Print Full Check'
                                                type='button'
                                                className='btn btn-outline-primary w-100 px-2'
                                                disabled={!checkHasItems}
                                                onClick={handlePrintFullCheck}
                                            />
                                        )
                                    }
                                </td>

                                <td className='text-right border-bottom-thick pr-0'>
                                    {
                                        (check.status === 'open' && allTabs.length > 1) ? <>
                                            {
                                                !check.individual_tab_payment_disabled_by_id ? (
                                                    <button
                                                        type='button'
                                                        className={`btn ${isPaid(check) ? 'btn-outline-primary' : 'btn-primary'} w-100 px-3 ${pendingComps.length > 0 ? 'comp-pending' : ''}`}
                                                        disabled={!transactionsAllowed || hasLockedTabs || check.has_unallocated_booking_credits}
                                                        onClick={handlePayFullCheck}
                                                    >
                                                        {hasLockedTabs && <span className='mr-2'><i className='fa-solid fa-lock' /></span>}
                                                        {isPartiallyPaid(check) && 'Pay Remaining Balance'}
                                                        {isUnpaid(check) && 'Pay Full Check'}
                                                        {isPaid(check) && 'Add Tip'}

                                                        {pendingComps.length > 0 && <span className='info-icon'><i className='fa-solid fa-info'></i></span>}
                                                    </button>
                                                ) : (
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-danger text-white px-2'
                                                        onClick={handleUnlockIndividualTabPayments}
                                                    >
                                                        <span className='mr-2'><i className='fa-solid fa-unlock' /></span>Unlock individual payments
                                                    </button>
                                                )
                                            }
                                        </> : <>
                                            {
                                                allTabs.length > 1 && (
                                                    <div className='btn px-1 text-white cursor-default w-100'>
                                                        <span className='text-uppercase mr-2 pr-1'>Closed</span>
                                                        <i className='fa-solid fa-check text-green fa-xl' />
                                                    </div>
                                                )
                                            }
                                        </>
                                    }
                                </td>
                            </tr>

                            {
                                allTabs.map((tab) => (
                                    <TabTableRow
                                        key={`tab-${tab.id}`}
                                        pendingComps={pendingComps}
                                        tab={tab}
                                        transactionsAllowed={transactionsAllowed}
                                        asFullCheckPayment={allTabs.length === 1}
                                        onConfirmCompPending={handleConfirmCompPending}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
