import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { logger as ActionCableLogger, createConsumer as ActionCableCreateConsumer } from '@rails/actioncable'

import {
    addCheck,
    addOrUpdateCheck,
    addOrUpdateCheckInAlteration,
    updateCheckLockState,
    updateTabLockState,
    removeCheckInAlteration,
    fetchChecks,
    fetchChecksInAlteration,
    setAllServers,
    setServersPendingShiftEnd,
    setTransactions,
} from '@/features/AdvancedPointOfSale/advancedPointOfSaleSlice'

import { setLocation } from '@/features/Locations/locationSlice'
import { updateIsSessionLocked, setSessionLocked, setCurrentUser } from '@/features/Session/sessionSlice'
import { addAlert } from '@/features/Notifications/notificationSlice'
import { IS_SOCKET_CONNECTED, DEVICE_UUID } from '@/lib/Storage'
import { debug } from '@/lib/Debug'

export default function AdvancedPointOfSaleChannelSubscription({ locationId }) {

    const socket   = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!locationId) {
            dispatch(addAlert({ type: 'error', text: 'Unable to establish connection.<br /><br />Location is unknown!' }))
        }

        // if we have a socket in state, unsubscribe from it since we need to switch to a different one here
        if (socket.current) {
            try {
                socket.current.unsubscribe()
            } catch (e) {
                console.log('Unable to unsubscribe from cable: ', e)
            }
        }

        if (debug) {
            ActionCableLogger.enabled = true
        }

        const subscription = ActionCableCreateConsumer().subscriptions.create({ channel: 'AdvancedPointOfSaleChannel', location_id: locationId }, {
            connected: () => {
                if (window.localStorage.getItem(IS_SOCKET_CONNECTED) == 'false') {
                    window.localStorage.setItem(IS_SOCKET_CONNECTED, 'true')
                    dispatch(addAlert({ type: 'success', text: 'RECONNECTED <i class="fas fa-circle-check float-right"></i>' }))
                }
            },
            disconnected: () => {
                if (window.localStorage.getItem(IS_SOCKET_CONNECTED) == 'true') {
                    window.localStorage.setItem(IS_SOCKET_CONNECTED, 'false')
                    dispatch(addAlert({ type: 'error', text: 'CONNECTION LOST' }))
                }

                if (!debug) {
                    dispatch(setSessionLocked(true))
                }
            },
            received: ({ data, type }) => {
                if (debug && console) { console.log('I have received data from the websocket.', [type, data]) }

                switch(type) {
                    case 'create_check' : {
                        dispatch(addCheck(data))
                        break
                    }

                    case 'update_check_lock_state' : {
                        dispatch(updateCheckLockState(data))
                        break
                    }

                    case 'update_tab_lock_state' : {
                        dispatch(updateTabLockState(data))
                        break
                    }

                    case 'update_check' : {
                        dispatch(addOrUpdateCheck(data))
                        break
                    }

                    case 'switch_device' : {
                        const userId = window.sessionStorage.adv_pos_session ? JSON.parse(window.sessionStorage.adv_pos_session).user.id : undefined

                        if (userId === data.user_id && (data.device_uuid === null || window.sessionStorage.getItem(DEVICE_UUID) !== data.device_uuid)) {
                            dispatch(updateIsSessionLocked(true, false))
                            .then(() => { dispatch(setSessionLocked(true)) })
                        }
                        break
                    }

                    case 'shift_ended' : {
                        dispatch(setCurrentUser(data.current_user))
                        dispatch(setAllServers(data.servers))

                        dispatch(setServersPendingShiftEnd(
                            data.servers.filter(server => (
                                moment(server.shift_ended_at).isSameOrBefore(moment(location.adv_pos_day_closed_at)))
                                || server.shift_ended_at === null
                            )
                        ))
                        break
                    }

                    case 'day_closed' : {
                        dispatch(setLocation(data))
                        dispatch(fetchChecks())
                        dispatch(fetchChecksInAlteration())
                        break
                    }

                    case 'check_in_alteration' : {
                        dispatch(addOrUpdateCheckInAlteration(data))
                        break
                    }

                    case 'check_not_in_alteration' : {
                        dispatch(removeCheckInAlteration(data))
                        break
                    }

                    case 'insert_or_update_transaction' : {
                        let group

                        switch(true) {
                            case !!data.booking_id && !data.check_id && !data.tab_id :
                                group = 'booking_payments'
                                break

                            case !!data.check_id && !data.tab_id :
                                group = 'check_payments'
                                break

                            case !!data.check_id && !!data.tab_id :
                                group = 'tab_payments'
                                break

                            default :
                                group = null
                        }

                        // NOTE
                        // It's possible to have group be unknown when a payment update
                        // is broadcast, such as when a pre-authorized, non-settled
                        // transaction is cancelled (i.e., a card on file is removed)
                        if (!!group) {
                            dispatch(setTransactions({
                                transaction: data.transaction,
                                check_id: data.check_id,
                                action: 'insertOrUpdate',
                                group: group,
                            }))
                        }

                        break
                    }

                    default : {
                        if (debug && console) { console.error('Something went kaboom during the broadcast. Type must not be set correctly. This should never be hit') }
                    }
                }
            }
        })

        // store the subscription in state so we can disconnect from it if the user switches resource types.
        window.localStorage.setItem(IS_SOCKET_CONNECTED, 'true')
        socket.current = subscription

        return () => { socket.current = null }
    }, [])

    return null
}
